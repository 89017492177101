import { server } from "@/code/server";
import * as models from "@/models";
import { Tracking } from "@/code/tracking";

export class messaging {
  // Control
  static createThread = (
    senderOrgId: number,
    recipientSeekerId: number,
    subject: string,
    messages: { text: string }[]
  ) => {
    return server
      .Post<models.thread>(
        "messaging/create",
        {
          senderOrgId,
          recipientSeekerId,
          subject,
          messages
        },
        true
      )
      .then(thrd => {
        Tracking.messaging.newThread();
        return thrd;
      });
  };

  static sendMessage = (
    threadId: number,
    text: string,
    senderOrgId?: number
  ) => {
    return server
      .Post<models.message>(
        "messaging/send",
        {
          threadId,
          text,
          senderOrgId
        },
        true
      )
      .then(msg => {
        Tracking.messaging.newMessage(!senderOrgId);
        return msg;
      });
  };

  static sendVideoMessage = (
    threadId: number,
    file: File,
    progressHandler: (percentage: number) => void,
    senderOrgId?: number
  ) => {
    let parts = file.name.split(".");
    let extension = parts[parts.length - 1];

    return server
      .PostMedia<models.message>(
        "messaging/sendVideo",
        {
          threadId,
          senderOrgId,
          extension: extension
        },
        file,
        true,
        progressHandler
      )
      .then(msg => {
        Tracking.messaging.newMessage(!senderOrgId);
        return msg;
      });
  };

  static markAsRead = (threadId: number, orgId?: number) => {
    return server
      .Post<boolean>(
        "messaging/markAsRead",
        {
          threadId,
          orgId
        },
        true
      )
      .then(() => true);
  };
  // View

  static getActiveThreads = (skip: number, take: number, orgId?: number) => {
    return messaging.getThreads_Paged("active", skip, take, orgId);
  };
  static getArchivedThreads = (skip: number, take: number, orgId?: number) => {
    return messaging.getThreads_Paged("archived", skip, take, orgId);
  };

  private static getThreads_Paged = (
    threadState: string,
    skip: number,
    take: number,
    orgId?: number
  ) => {
    return server.Post<models.paged<models.thread>>(
      "messaging/getThreads",
      {
        threadState,
        orgId,
        skip: skip,
        take: take
      },
      true
    );
  };

  static getThread = (
    threadId: number,
    skip: number,
    take: number,
    orgId?: number
  ) => {
    return server.Post<models.thread>(
      "messaging/getThread",
      {
        threadId,
        orgId,
        skip: skip,
        take: take
      },
      true
    );
  };
  static getThreadBySeekerId = (
    seekerId: number,
    skip: number,
    take: number,
    orgId: number
  ) => {
    return server.Post<models.thread>(
      "messaging/getThreadBySeekerId",
      {
        seekerId,
        orgId,
        skip: skip,
        take: take
      },
      true
    );
  };
  static getThreadVideoMessages = (threadId: number) => {
    return server.Post<models.message[]>(
      "messaging/getThreadVideoMessages",
      {
        threadId
      },
      true
    );
  };

  static getUnviewedMessagesCount = (
    threadId: number,
    lastMessageId: number
  ) => {
    return server.Post<number>(
      "messaging/getUnviewedMessagesCount",
      {
        threadId,
        lastMessageId
      },
      false
    );
  };

  static getNewMessagesCount = (orgId?: number) => {
    return server.Post<number>(
      "messaging/getNewMessagesCount",
      {
        orgId
      },
      true
    );
  };
}
