import axios, { AxiosRequestConfig } from "axios";
import { G } from "@/globals";
import * as models from "@/models";
// const cachios = require('cachios');
//import cachios from "cachios";

export class server {
  private static get IsLocalhost(): boolean {
    // = Always connect to online server (Production or Development)
    return false;
    // = Connect to local backend if client is served from localhost
    //  return window.location.href.startsWith("http://localhost");
  }

  private static get ApiServerUrl(): string {
    return server.IsLocalhost
      ? "//localhost/nucleus/v0/api/"
      : process.env.VUE_APP_API_ServerUrl;
    // : "//meinone.azurewebsites.net/v0/api/";
  }

  public static Post<t>(
    resourceUri: string,
    data?: any,
    includeToken?: boolean
  ): Promise<t> {
    resourceUri = server.ApiServerUrl + resourceUri;

    if (!data) data = {};

    return new Promise<t>((resolve, reject) => {
      if (includeToken)
        if (G.data.currentLogin)
          data = { ...data, ...{ token: G.data.currentLogin.token } };
        else throw Error("Unxpected call: G.currentLogin is null!");

      axios.post<models.apiResult<t>>(resourceUri, data).then(
        response => {
          let result = response.data;
          if (result.isResolved) {
            resolve(result.body);
          } else {
            reject(result.rejectionReason);
          }
        },
        error => reject(error)
      );
    });
  }

  public static PostMedia<t>(
    resourceUri: string,
    data?: any,
    mediaFile?: File,
    includeToken?: boolean,
    progressHandler?: (percentage: number) => void
  ): Promise<t> {
    resourceUri = server.ApiServerUrl + resourceUri;

    if (!data) data = {};
    if (includeToken)
      if (G.data.currentLogin)
        data = { ...data, ...{ token: G.data.currentLogin.token } };
      else throw Error("Unxpected call: G.currentLogin is null!");

    let formData = new FormData();
    formData.append("video", mediaFile);
    formData.append("data", JSON.stringify(data));

    var config = {
      onUploadProgress: function(progressEvent: any) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (progressHandler) progressHandler(percentCompleted);
      },
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*"
      }
    };

    return new Promise<t>((resolve, reject) => {
      axios.post<models.apiResult<t>>(resourceUri, formData, config).then(
        response => {
          let result = response.data;
          if (result.isResolved) {
            resolve(result.body);
          } else {
            reject(result.rejectionReason);
          }
        },
        error => reject(error)
      );
    });
  }

  public static Get<t>(resourceUri: string, params?: {}): Promise<t> {
    resourceUri = server.ApiServerUrl + resourceUri;
    let config: AxiosRequestConfig = {};
    if (params) config = { params: params };
    return new Promise<t>((resolve, reject) => {
      axios.get<models.apiResult<t>>(resourceUri, config).then(
        response => {
          let result = response.data;
          if (result.isResolved) {
            resolve(result.body);
          } else {
            reject(result.rejectionReason);
          }
        },
        error => reject(error)
      );
    });
  }
}
