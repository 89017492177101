











import Vue from "vue";
export default {
  name: "dotSteps",
  props: {
    steps: {
      type: Number,
      default: 3,
      required: false
    },
    currentStep: {
      type: Number,
      default: 1,
      required: false
    },
    progressive: {
      type: Boolean,
      default: false,
      required: false
    },
    cssClass: {
      default: null,
      required: false
    },
    cssStyle: {
      default: null,
      required: false
    },
    dotColor: {
      type: String,
      default: "accent",
      required: false
    }
    // shadow: {
    //   type: Number,
    //   default: 4,
    //   required: false
    // },
  },

  data() {
    return {};
  },
  watch: {},
  computed: {},
  created() {},
  mounted() {},
  methods: {}
};
