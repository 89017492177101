var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-textarea",
        _vm._b(
          {
            staticClass: "round-outline",
            attrs: {
              rows: 1,
              "auto-grow": "",
              label: _vm.title,
              placeholder: _vm.placeholder,
              value: _vm.valueItemsText,
              readonly: "",
              "append-icon": "expand_more"
            },
            on: {
              click: function($event) {
                _vm.selectorVisible = true
              },
              "click:append": function($event) {
                _vm.selectorVisible = true
              }
            }
          },
          "v-textarea",
          _vm.$attrs,
          false
        )
      ),
      _c(
        "v-bottom-sheet",
        {
          attrs: {
            inset: "",
            lazy: "",
            "xfull-width": "false",
            "xmax-width": "900",
            persistent: ""
          },
          model: {
            value: _vm.selectorVisible,
            callback: function($$v) {
              _vm.selectorVisible = $$v
            },
            expression: "selectorVisible"
          }
        },
        [
          _c(
            "v-card",
            {
              staticStyle: {
                "border-radius": "45px  45px 0 0",
                padding: "15px 15px 0"
              }
            },
            [
              _vm.multiple
                ? _c(
                    "v-btn",
                    {
                      staticStyle: { bottom: "16px" },
                      attrs: {
                        fab: "",
                        color: "primary",
                        bottom: "",
                        right: "",
                        absolute: ""
                      },
                      on: { click: _vm.updateValue }
                    },
                    [_c("v-icon", [_vm._v("check")])],
                    1
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  staticStyle: { bottom: "16px" },
                  attrs: {
                    fab: "",
                    color: "white primary--text",
                    bottom: "",
                    left: "",
                    absolute: ""
                  },
                  on: {
                    click: function($event) {
                      _vm.selectorVisible = false
                    }
                  }
                },
                [_c("v-icon", [_vm._v("close")])],
                1
              ),
              _c(
                "v-card-text",
                [
                  false && _vm.multiple
                    ? _vm._l(_vm.selectedItems, function(itm) {
                        return _c(
                          "v-chip",
                          {
                            key: itm.itemValue,
                            attrs: {
                              dark: "",
                              small: "",
                              color: "primary ",
                              ripple: ""
                            },
                            on: {
                              click: function($event) {
                                return _vm.selectItem(itm)
                              }
                            }
                          },
                          [_vm._v(_vm._s(itm.itemText))]
                        )
                      })
                    : _vm._e(),
                  _c("v-text-field", {
                    staticClass: "round-outline",
                    attrs: {
                      outline: "",
                      "append-icon": _vm.filter ? "close" : null,
                      "hide-details": "",
                      label: "Filter"
                    },
                    on: {
                      "click:append": function() {
                        _vm.filter = null
                      }
                    },
                    model: {
                      value: _vm.filter,
                      callback: function($$v) {
                        _vm.filter = $$v
                      },
                      expression: "filter"
                    }
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "baby-scroll",
                      staticStyle: {
                        "max-height": "50vh",
                        "overflow-y": "auto",
                        "padding-bottom": "72px"
                      }
                    },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "text-xs-center",
                          staticStyle: { "margin-top": "6px" },
                          attrs: { flat: "" }
                        },
                        _vm._l(_vm.groupHeaders, function(lt) {
                          return _c(
                            "v-btn",
                            {
                              key: lt,
                              staticStyle: { margin: "3px" },
                              attrs: {
                                flat: _vm.selectedGroupLetter != lt,
                                small: "",
                                icon: "",
                                color: "accent darken-1"
                              },
                              on: {
                                click: function($event) {
                                  _vm.selectedGroupLetter = lt
                                }
                              }
                            },
                            [_vm._v(_vm._s(lt))]
                          )
                        }),
                        1
                      ),
                      _vm.multiple && _vm.allowAll
                        ? _c(
                            "v-chip",
                            {
                              attrs: {
                                dark: _vm.selectedItems.length == 0,
                                color:
                                  _vm.selectedItems.length == 0
                                    ? "primary"
                                    : "grey",
                                outline: !_vm.selectedItems.length == 0,
                                ripple: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.clearSelection()
                                }
                              }
                            },
                            [_vm._v("ALL")]
                          )
                        : _vm._e(),
                      _vm._l(_vm.filteredMappedItems, function(itm) {
                        return _c(
                          "v-chip",
                          {
                            key: itm.itemValue,
                            attrs: {
                              dark: itm.selected,
                              xclose: "itm.selected && multiple",
                              color: itm.selected ? "primary" : "grey",
                              outline: !itm.selected,
                              ripple: ""
                            },
                            on: {
                              click: function($event) {
                                return _vm.selectItem(itm)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(itm.itemText) +
                                "\n              "
                            ),
                            itm.selected && _vm.multiple
                              ? _c("v-icon", { attrs: { right: "" } }, [
                                  _vm._v("close")
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }