var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mp-page",
      class: _vm.bodyColor,
      staticStyle: { "padding-top": "15px" }
    },
    [
      _c(
        "div",
        { staticClass: "mp-body", class: _vm.bodyColor, attrs: { dark: "" } },
        [
          _c("div", { staticClass: "mp-header" }, [_vm._t("header")], 2),
          _c(
            "div",
            {
              staticClass: "mp-header-extra",
              class: [
                { "mp-hide": _vm.extraReady && !_vm.extraOpen },
                { "mp-show": _vm.extraReady && _vm.extraOpen }
              ]
            },
            [_vm._t("extra")],
            2
          ),
          _c(
            "div",
            {
              staticClass: "mp-content",
              staticStyle: { background: "white", color: "black" }
            },
            [_c("div", { staticClass: "baby-scroll" }, [_vm._t("default")], 2)]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }