import { server } from "@/code/server";
// import * as models from "@/models";
import { Tracking } from "@/code/tracking";

export class pushMessaging {
  // Control
  static addFcmToken = (fcmToken: string) => {
    return server
      .Post<void>("pushMessaging/addFcmToken", { fcmToken }, true)
      .then(() => {
        Tracking.pushMessaging.AddFcmToken();
        return;
      });
  };
  static removeFcmToken = (fcmToken: string) => {
    return server
      .Post<void>("pushMessaging/removeFcmToken", { fcmToken }, true)
      .then(() => {
        Tracking.pushMessaging.AddFcmToken();
        return;
      });
  };
}
