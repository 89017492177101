


































import Vue from "vue";
import * as models from "@/models";
import Avatar from "vue-avatar";

export default {
  name: "orgSelectorDialog",
  components: { Avatar: Avatar },
  props: {
    orgs: {
      required: false,
      default: null
    },
    canCancel: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data: () => ({
    dialog: false,
    resolver: null,
    internalOrgs: null
  }),
  created: function() {
    //  this.orgs[0].
  },
  mounted: function() {
    if (this.orgs) this.internalOrgs = this.orgs;
  },
  methods: {
    show: function(orgs: models.org[]): Promise<models.org> {
      this.internalOrgs = orgs;
      this.dialog = true;
      return new Promise<models.org>((resolve, reject) => {
        this.resolver = resolve;
      });
    },
    hide: function() {
      this.dialog = false;
    },
    select: function(org: models.org) {
      this.hide();
      this.resolver(org);
      this.$emit("select", org);
    }
  },
  computed: {}
};
