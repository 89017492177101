import { server } from "@/code/server";
import { Tracking } from "@/code/tracking";

export class feedback {
  static post = (feedbackData: {
    subject: string;
    message: string;
    name: string;
    email: string;
    id?: number;
  }) => {
    Tracking.feedback.post(feedbackData.subject);
    return server
      .Post<any>("feedback/post", {
        loginId: feedbackData.id,
        name: feedbackData.name,
        email: feedbackData.email,
        subject: feedbackData.subject,
        message: feedbackData.message
      })
      .then(() => true);
  };
}
