
































import Vue from "vue";
import * as models from "@/models";
import { G } from "@/globals";
import { info } from "@/code/api.info";

export default {
  name: "DynamicSplitView",
  components: {},
  props: {
    bodyColor: {
      required: false,
      default: "primary white--text",
      type: String
    },
    extraOpen: {
      required: false,
      default: false,
      type: Boolean
    }
  },
  data: () => ({
    // extraVisible: false,
    extraReady: false
  }),
  watch: {
    extraOpen: function(newValue: boolean) {
      this.extraReady = true;
    }
  },
  computed: {},
  created: function() {},
  mounted: function() {},
  methods: {
    // toggleExtra: function() {
    //   this.extraVisible = !this.extraVisible;
    // }
  }
};
