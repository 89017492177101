import Vue from "vue";
// import App from "./App.vue";
import App from "./App.vue";
import { router, ViewAccessType } from "./router";
import "./registerServiceWorker";
import { G, util } from "@/globals";
// import * as models from "./models";
import { accounts } from "@/code/api.accounts";
// import * as confirm from "@/components/confirm/confirm";
// import "@/assets/main.styl";
// import "vuetify/dist/vuetify.css"; // Ensure you are using css-loader

const trace = G.fbPref.trace("main.ts ;)");
trace.start();

Vue.config.productionTip = false;
//------------------------------------------------------------------
import Vuetify from "vuetify";
// export const colors = {
//   seeker: {
//     primary: "#3bb18f", //"#44A3A9", // "#ac8d65
//     secondary: "#C1EA82", // "#85BA46",
//     accent: "#eed9a9"
//   },
//   recruiter: {
//     primary: "#05435c", //"#2a6266", // "#ac8d65
//     secondary: "#fcdf8e", //"#C1EA82", // "#85BA46",
//     accent: "#fcd18e"
//   }
// };
export const colors = {
  seeker: {
    // #422e7a <-- PURPLE!
    primary: "#05435C", //"#44A3A9", // "#ac8d65
    secondary: "#C1EA82", // "#85BA46",
    accent: "#eed9a9"
  },
  recruiter: {
    primary: "#05435C", //"#25555f", //"#1A3946", //"#05435c", //"#2a6266", // "#ac8d65
    secondary: "#fcdf8e", //"#C1EA82", // "#85BA46",
    accent: "#fcd18e"
  }
};
Vue.use(Vuetify, {
  theme: {
    primary: colors.seeker.primary, //1C2E63
    secondary: colors.seeker.secondary, //905794
    accent: "#fec02d",
    error: "#d76780", //"#C62828",
    info: "#67a8d7", //"#1565C0",
    success: "#97d767", // "#2E7D32",
    warning: "#d7a067" // "#efa28a" //"#D84315"
  },
  iconfont: "md",
  options: {
    customProperties: true
  }
});
//------------------------------------------------------------------
import VueMoment from "vue-moment";
// import moment from "moment-timezone";
Vue.use(VueMoment, {
  // moment, (Use later for time-zone functions!)
});
//------------------------------------------------------------------
import VueNativeNotification from "vue-native-notification";

Vue.use(VueNativeNotification, {
  // Automatic permission request before
  // showing notification (default: true)
  requestOnNotify: false
});

//------------------------------------------------------------------
import VueAnalytics from "vue-analytics";
Vue.use(VueAnalytics, {
  id: util.isLocalhost
    ? "UA-125497308-2"
    : process.env.VUE_APP_GoogleAnalyticsId,
  // autoTracking: {
  //   screenview: true
  // },
  router
});
//------------------------------------------------------------------
// import VueSweetalert2 from "vue-sweetalert2";
// // import swal from 'sweetalert2'
// // import 'sweetalert2/dist/sweetalert2.css'

// Vue.use(VueSweetalert2, {
//   confirmButtonColor: "--v-primary-base",
//   cancelButtonColor: "--v-accent-base"
//   // confirmButtonColor: "#00FF00",
//   // cancelButtonColor: "#FF0000"
// });
// - VueSweetalert2 -------------------------------------
import VueSweetalert2 from "vue-sweetalert2";
Vue.use(VueSweetalert2, {
  confirmButtonColor: "var(--v-primary-base)",
  cancelButtonColor: "var(--v-accent-base)"
  // confirmButtonColor: "#00FF00",
  // cancelButtonColor: "#FF0000"
});
// ----------------------------------------------------------

//------------------------------------------------------------------

router.beforeEach(async (to, from, next) => {
  await G.data.waitForAutoLogin();
  // let home = G.data.isSeekerMode
  //   ? { name: "Seekers_Discover" }
  //   : { name: "Orgs_Discover" };
  let home = { name: "Home" };

  if (!to.meta || !to.meta.accessType) {
    next(home);
  } else {
    let at: ViewAccessType = to.meta.accessType;

    // Everyone = Proceed
    if (at == ViewAccessType.Everyone) next();
    else if (/* Logged OUT View*/ at == ViewAccessType.LoggedOut) {
      // Logged Out View + Current Login Exists = Redirect Home
      if (G.data.currentLogin) next(home);
      // Logged Out View + No Current Login = Proceed
      else next();
    } else if (/* Logged IN View */ at == ViewAccessType.AllLoggedIn) {
      // Logged In View + Current Login Exists = Proceed
      if (G.data.currentLogin) next();
      // Logged In View + No Current Login = Redirect Home
      else next("/login");
    }
  }
  G.data.darkPage = false;
  if (!!to.meta && !!to.meta.viewSettings) {
    G.data.darkPage = to.meta.viewSettings.dark;
    if (G.topLevelVue) G.topLevelVue.currentViewSettings = to.meta.viewSettings;
  }
});

//------------------------------------------------------------------
// globalMixins
import globalMixins from "./globalMixins";
Vue.mixin(globalMixins);
//------------------------------------------------------------------
// Clipboard
import Clipboard from "v-clipboard";
Vue.use(Clipboard);
//------------------------------------------------------------------
// components
import advancedSelector from "@/components/advanced-selector.vue";
Vue.component("advanced-selector", advancedSelector);
import feedbackDialog from "@/components/feedback-dialog.vue";
Vue.component("feedback-dialog", feedbackDialog);
import mi1Logo from "@/components/logo.vue";
Vue.component("mi1-logo", mi1Logo);
import mPage from "@/components/ui/m-page.vue";
Vue.component("m-page", mPage);
import mToolbar from "@/components/ui/m-toolbar.vue";
Vue.component("m-toolbar", mToolbar);
import mTabs from "@/components/ui/m-tabs.vue";
Vue.component("m-tabs", mTabs);
import dotSteps from "@/components/dot-steps.vue";
Vue.component("dot-steps", dotSteps);
//------------------------------------------------------------------

new Vue({
  data: {
    G: G.data
  },
  router: router,
  watch: {
    $route(to, from) {
      G.data.customTitle = null;

      // //console.log(to.meta.viewSettings);
    }
  },
  methods: {},
  created: function () {
    // G.confirmDialog = confirm.getConfirmDialog(this);
  },
  mounted: function () {
    console.log({ isSeekerMode: accounts.isSeekerMode });
    G.setSeekerMode(accounts.isSeekerMode, this);
  },
  render: h => h(App)
}).$mount("#app");

// code that you want to trace

trace.stop();
