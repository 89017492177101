import { G } from "@/globals";
import { fbMsg } from "@/code/firebase";
import { pushMessaging } from "@/code/api.push-messaging";
import { notifications } from "@/code/api.notifications";

// Initialize Notification
export const initNotification = () => {
  let setToken = async (receivedToken: string) => {
    let currentLoginId = G.data.currentLogin.id;
    let local = G.localFcmToken;
    if (
      !!local &&
      local.loginId == currentLoginId &&
      local.fcmToken == receivedToken
    ) {
      // Tokens identical: Do nothing
      return;
    }

    if (!!local && local.fcmToken != receivedToken) {
      //We have old token: remove it
      await pushMessaging.removeFcmToken(local.fcmToken);
    }

    // Add new token and save it
    await pushMessaging.addFcmToken(receivedToken);
    G.localFcmToken = { loginId: currentLoginId, fcmToken: receivedToken };
  };
  let win = <any>window;
  let permission =
    !win.Notification || !win.Notification.permission
      ? "default"
      : win.Notification.permission;
  if (fbMsg && permission == "granted") {
    fbMsg
      .getToken()
      .then(async receivedToken => {
        if (receivedToken) {
          console.log("token: ", receivedToken);
          setToken(receivedToken);
        } else {
          // Show permission request.
          console.log(
            "No Instance ID token available. Request permission to generate one."
          );
        }
      })
      .catch(err => {
        console.log("An error occurred while retrieving token. ", err);
        // showToken("Error retrieving Instance ID token. ", err);
        // setTokenSentToServer(false);
      });

    // Callback fired if Instance ID token is updated.
    fbMsg.onTokenRefresh(() => {
      fbMsg
        .getToken()
        .then(refreshedToken => {
          console.log("Token refreshed.", refreshedToken);
          setToken(refreshedToken);
        })
        .catch(err => {
          console.log("Unable to retrieve refreshed token ", err);
          // showToken("Unable to retrieve refreshed token ", err);
        });
    });
  }
};

// Manage
export class notiOps {
  static getPaged = (skip: number) => {
    let notificationsData = G.data.notificationsData;
    return notifications
      .getPaged(skip, notificationsData.pageSize)
      .then(pagedNotis => {
        if (!notificationsData.pagedNotifications || skip == 0) {
          notificationsData.pagedNotifications = pagedNotis;
        } else {
          let combinedItems = [
            ...notificationsData.pagedNotifications.items,
            ...pagedNotis.items
          ];
          notificationsData.pagedNotifications = pagedNotis;
          notificationsData.pagedNotifications.items = combinedItems;
        }
        notiOps.calculateNewCount();
      })
      .catch(err => {
        G.log.error("notificationOps.notiOps.getPaged", err);
      })
      .finally(() => {
        notificationsData.loadingMore = false;
      });
  };

  static loadMore = () => {
    let notificationsData = G.data.notificationsData;
    notificationsData.loadingMore = true;
    return notiOps.getPaged(notificationsData.pagedNotifications.items.length);
  };

  static refresh = () => {
    console.log("Started refreshing notifications");

    //Nothing to get after, get paged instead
    let notificationsData = G.data.notificationsData;
    if (
      !notificationsData.pagedNotifications ||
      !notificationsData.pagedNotifications.items ||
      notificationsData.pagedNotifications.items.length == 0
    ) {
      return notiOps.getPaged(0);
    }

    // Procees
    let lastReceivedId = Math.max(
      ...notificationsData.pagedNotifications.items.map(n => n.id),
      0
    );

    notificationsData.loadingMore = true;
    return notifications
      .getAfter(lastReceivedId)
      .then(notis => {
        let combinedItems = [
          ...notis,
          ...notificationsData.pagedNotifications.items
        ];

        notificationsData.pagedNotifications.items = combinedItems;
        notiOps.calculateNewCount();
      })
      .catch(err => {
        G.log.error("notificationOps.notiOps.getAfter", err);
      })
      .finally(() => {
        notificationsData.loadingMore = false;
      });
  };

  static calculateNewCount = () => {
    let notificationsData = G.data.notificationsData;
    notificationsData.newCount = notificationsData.pagedNotifications.items.filter(
      n => !n.readInAppDate
    ).length;
  };
}
