














































































































































































import Vue from "vue";

export default {
  inheritAttrs: false,
  name: "advancedSelector",
  props: {
    value: {
      // required: false
      default: null
    },
    multiple: {
      required: false,
      type: Boolean,
      default: false
    },
    allowAll: {
      required: false,
      type: Boolean,
      default: false
    },
    items: {
      required: false,
      default: () => []
    },
    title: {
      required: false,
      type: String,
      default: "Select..."
    },
    placeholder: {
      required: false,
      type: String,
      default: null
    },
    itemTextField: {
      required: true,
      type: String,
      default: "text"
    },
    itemValueField: {
      required: true,
      type: String,
      default: "text"
    },
    grouped: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    selectorVisible: false,
    filter: null,
    mappedItems: null,
    selectedGroupLetter: "A"
  }),
  watch: {
    value: function(newValue) {
      this.readSelectedItemsFromInputValue();
    },
    selectorVisible: function(newValue) {
      if (!newValue) return;
      this.filter = null;
      this.readSelectedItemsFromInputValue();
    },
    items: function(newValue) {
      this.mappedItems = newValue.map(item => ({
        itemText: item[this.itemTextField],
        itemValue: item[this.itemValueField],
        original: item,
        selected: false
      }));
      this.readSelectedItemsFromInputValue();
    }
  },
  created: function() {
    //  this.orgs[0].
  },
  mounted: function() {
    // if (this.orgs) this.internalOrgs = this.orgs;
    // this.readSelectedItemsFromInputValue();
  },
  methods: {
    readSelectedItemsFromInputValue: function() {
      if (!this.mappedItems) return;
      let mi: Array<any> = this.mappedItems;
      mi.filter(si => si.selected).forEach(i => (i.selected = false));

      if (this.value && this.multiple) {
        mi.filter(si =>
          this.value.some(item => item[this.itemValueField] == si.itemValue)
        ).forEach(i => (i.selected = true));
      }
      if (this.value && !this.multiple) {
        mi.filter(
          si => this.value[this.itemValueField] == si.itemValue
        )[0].selected = true;
      }
    },
    selectItem: function(item) {
      let ci: Array<any> = this.mappedItems;
      if (this.multiple) {
        // item.selected = !item.selected;
        ci.filter(si => si.itemValue == item.itemValue).forEach(
          i => (i.selected = !i.selected)
        );
      } else {
        ci.filter(si => si.selected).forEach(i => (i.selected = false));
        item.selected = true;
        this.updateValue();
      }
    },
    clearSelection: function() {
      let ci: Array<any> = this.mappedItems;
      ci.filter(si => si.selected).forEach(i => (i.selected = false));
    },
    updateValue() {
      this.$emit(
        "input",
        this.multiple
          ? this.selectedItems.map(item => item.original)
          : this.selectedItems[0].original
      );
      this.selectorVisible = false;
    }
  },
  computed: {
    groupedByFirstLetter: function() {
      if (!this.mappedItems || !this.grouped) return [];

      let mappedItems: {
        itemText: string;
        itemValue: any;
        original: any;
        selected: boolean;
      }[] = this.mappedItems;

      let data = mappedItems.reduce((r, e) => {
        // get first letter of name of current element
        let group = e.itemText[0];
        // if there is no property in accumulator with this letter create it
        if (!r[group]) r[group] = { group, children: [e] };
        // if there is push current element to children array for that letter
        else r[group].children.push(e);
        // return accumulator
        return r;
      }, {});

      return data;
    },
    groupHeaders: function() {
      return Object.keys(this.groupedByFirstLetter);
    },
    filteredMappedItems: function() {
      if (this.mappedItems && this.filter) {
        return this.mappedItems.filter(item =>
          item.itemText.toLowerCase().includes(this.filter.toLowerCase())
        );
      } else if (this.grouped) {
        let fg = this.groupedByFirstLetter[this.selectedGroupLetter];

        // let fg = this.groupedByFirstLetter.filter(
        //   g => g.group == this.selectedGroupLetter
        // );
        if (!fg) return [];
        return fg.children;
      } else {
        return this.mappedItems;
      }
    },

    selectedItems: function() {
      if (!this.mappedItems) return [];
      return this.mappedItems.filter(itm => itm.selected);
    },
    valueItems: function() {
      if (!this.mappedItems) return;
      if (this.value && this.multiple) {
        return this.mappedItems.filter(si =>
          this.value.some(item => item[this.itemValueField] == si.itemValue)
        );
      }
      if (this.value && !this.multiple) {
        return this.mappedItems.filter(
          si => this.value[this.itemValueField] == si.itemValue
        );
      }
    },
    selectedItemsText: {
      cache: false,
      get: function() {
        if (this.selectedItems.length == 0) return null;
        return (
          this.selectedItems
            .map(item => item["itemText"])
            /*.reverse()*/
            .join(", ")
        );
      }
    },
    valueItemsText: function() {
      if (!this.valueItems || this.valueItems.length == 0) {
        if (this.allowAll) {
          return "All";
        } else {
          return null;
        }
      }
      return (
        this.valueItems
          .map(item => item["itemText"])
          /*.reverse()*/
          .join(", ")
      );
    }
  }
};
