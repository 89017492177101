var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticClass: "m-page fill-height",
      class: _vm.toolbarVisible ? "has-toolbar" : "",
      attrs: { row: "", wrap: "" }
    },
    [
      !_vm.childHasContent ||
      (_vm.isParentChild &&
        _vm.childHasContent &&
        _vm.$vuetify.breakpoint.smAndUp)
        ? _c(
            "v-flex",
            {
              class: _vm.parentClass,
              staticStyle: { position: "relative" },
              attrs: { xs12: "", sm4: _vm.childHasContent }
            },
            [
              _vm.toolbarVisible ? _vm._t("toolbar") : _vm._e(),
              _c(
                "div",
                { staticClass: "scroll-wrapper baby-scroll" },
                [_vm._t("default")],
                2
              )
            ],
            2
          )
        : _vm._e(),
      _c(
        "v-flex",
        {
          staticClass: "baby-scroll",
          class:
            _vm.isParentChild && _vm.$vuetify.breakpoint.smAndUp
              ? "child-view"
              : "",
          staticStyle: { position: "relative" },
          style: { display: _vm.childHasContent ? "" : "none" },
          attrs: { xs12: "", sm8: _vm.isParentChild && _vm.childHasContent }
        },
        [
          _c("router-view", {
            attrs: {
              "hook:beforeCreate": "childRouteChanged(true)",
              "xhook:destroyed": "childRouteChanged(false)"
            },
            on: {
              "hook:beforeDestroy": function($event) {
                return _vm.childRouteChanged(false)
              },
              "hook:mounted": function($event) {
                return _vm.childRouteChanged(true)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }