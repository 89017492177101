var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper", class: _vm.cssClass, style: _vm.cssStyle },
    _vm._l(_vm.steps, function(i) {
      return _c(
        "v-icon",
        {
          key: i,
          class: [
            {
              on:
                _vm.currentStep == i ||
                (_vm.progressive && _vm.currentStep >= i)
            }
          ],
          attrs: { color: _vm.dotColor }
        },
        [_vm._v("lens")]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }