

























































































































































import Vue from "vue";
import { rules, G } from "@/globals";
import { accounts } from "@/code/api.accounts";
import * as models from "@/models";
import orgsSelectorDialog from "@/components/orgs/orgsSelectorDialog.vue";
import { Tracking } from "@/code/tracking";

export default {
  name: "Login",
  data: () => ({
    signinView: true,
    loggingIn: false,
    formValid: false,
    email: "",
    password: "",
    viewError: "",
    rules: {
      required: rules.required,
      maxCounter: rules.maxCounter,
      email: rules.email
    },
    passwordReset: {
      lastName: null,
      formValid: false,
      requesting: false,
      requested: false
    }
  }),
  created: () => {},
  methods: {
    setCurrentSubView: function(signIn: boolean) {
      this.signinView = signIn;
      // if (!signIn) this.$refs.resetForm.reset();
      // if (signIn) this.$refs.form.reset();
    },
    login: async function() {
      let form = <any>this.$refs.form;
      let valid = form.validate();
      if (!valid) return;

      this.loggingIn = true;
      this.viewError = "";
      return accounts
        .login(this.email, this.password)
        .then(async login => {
          console.log(login);
          G.setSeekerMode(accounts.isSeekerMode, this, false);
          // //Exceptional Case
          // {
          //   // Recruiter with ZERO orgs!
          //   if (!accounts.isSeekerMode && login.orgs.length == 0) {
          //     accounts.isSeekerMode = true;
          //     G.setSeekerMode(false, this, true);
          //     console.log("ZERO orgs!");
          //   }
          // }

          if (!accounts.isSeekerMode) await this.processCurrentOrg(login.orgs);
          G.showLoading();
          this.$router.push({ name: "Home" });
        })
        .catch(e => {
          console.error(e);
          this.viewError = e;
          G.log.error("Login.vue.login", e);
        })
        .finally(() => {
          this.loggingIn = false;
        });
    },
    processCurrentOrg: async function(orgs: models.org[]) {
      if (accounts.currentOrgId) return;
      if (orgs.length == 0) {
        accounts.isSeekerMode = true;
        G.setSeekerMode(true, this, true);
        console.log("ZERO orgs!");
        return;
      }
      let orgSelector: any = this.$refs.orgSelector;
      let resultOrg: models.org = await orgSelector.show(orgs);
      accounts.currentOrgId = resultOrg.id;
    },
    requestPasswordReset: function() {
      let resetForm = <any>this.$refs.resetForm;
      let valid = resetForm.validate();
      if (!valid) return;

      this.passwordReset.requesting = true;
      this.viewError = "";
      return accounts
        .requestPasswordReset(this.email, this.passwordReset.lastName)
        .then(() => {
          this.passwordReset.requested = true;
          Tracking.accounts.resetPasswordRequest();
        })
        .catch(e => {
          console.error(e);
          this.viewError = e;
        })
        .finally(() => {
          this.passwordReset.requesting = false;
        });
    }
  },
  computed: {
    ver: () => G.data.ver
  },
  components: { orgsSelectorDialog }
};
