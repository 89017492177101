var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        scrollable: "",
        persistent: !_vm.canCancel,
        "xmax-width": "300px"
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("Select Business")]),
          _c("v-divider"),
          _c(
            "v-card-text",
            { staticClass: "pa-0", staticStyle: { "max-height": "300px" } },
            [
              _c(
                "v-list",
                { attrs: { "two-line": "" } },
                [
                  _vm._l(_vm.internalOrgs, function(org, index) {
                    return [
                      _c(
                        "v-list-tile",
                        {
                          key: org.id,
                          attrs: { avatar: "", ripple: "" },
                          on: {
                            click: function($event) {
                              return _vm.select(org)
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-tile-avatar",
                            [
                              _c("avatar", {
                                attrs: {
                                  username: org.name,
                                  size: 40,
                                  backgroundColor: _vm.$vuetify.theme.primary
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-list-tile-content",
                            [
                              _c("v-list-tile-title", [
                                _vm._v(_vm._s(org.name))
                              ]),
                              _c("v-list-tile-sub-title", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(org.industry.title) +
                                    "\n                —\n                " +
                                    _vm._s(org.city.id) +
                                    "\n              "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      index + 1 < _vm.internalOrgs.length
                        ? _c("v-divider", { key: index })
                        : _vm._e()
                    ]
                  })
                ],
                2
              )
            ],
            1
          ),
          _c("v-divider"),
          _vm.canCancel
            ? _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "default", flat: "" },
                      on: {
                        click: function($event) {
                          return _vm.select(null)
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }