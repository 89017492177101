var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper", class: _vm.cssClass, style: _vm.cssStyle },
    [
      _c(
        "svg",
        {
          class: { anim: _vm.animate },
          staticStyle: {
            "shape-rendering": "geometricPrecision",
            "text-rendering": "geometricPrecision",
            "image-rendering": "optimizeQuality",
            "fill-rule": "evenodd",
            "clip-rule": "evenodd"
          },
          style: [
            {
              "-webkit-filter":
                "drop-shadow(0 0 " +
                _vm.shadow.toString() +
                "px rgba(0, 0, 0, 0.3))"
            },
            {
              filter:
                "drop-shadow(0 0 " +
                _vm.shadow.toString() +
                "px rgba(0, 0, 0, 0.3)) "
            }
          ],
          attrs: {
            "xx-xmlns": "http://www.w3.org/2000/svg",
            "xx-xmlns:xlink": "http://www.w3.org/1999/xlink",
            "xx-xml:space": "preserve",
            "xx-width": "2in",
            "xx-height": "7in",
            version: "1.1",
            viewBox: "0 0 2000 7000"
          }
        },
        [
          _c(
            "g",
            { attrs: { id: "Layer_x0020_1" } },
            [
              _c("metadata", { attrs: { id: "CorelCorpID_0Corel-Layer" } }),
              _c("g", { attrs: { id: "_1445254167776" } }, [
                _c("path", {
                  staticClass: "fil0 str0",
                  style: [
                    { stroke: _vm.borderColor },
                    { fill: _vm.fillColor },
                    { "stroke-width": _vm.borderWidth + "em !important" }
                  ],
                  attrs: {
                    d:
                      "M337.02 6936.07c106.22,0 126.81,-10.96 206.71,-72.02 20.18,-15.43 39.61,-42.17 54.81,-65.33 73.19,-111.58 1.8,-137.26 2.65,-218.12 2.18,-208.52 -14.28,-453.95 -14.27,-663.06 0,-139.83 -5.52,-266.47 -4.78,-408.65 0.2,-38.77 -5.07,-57.4 -4.87,-96.19 0.46,-90.32 2.05,-233.26 -4.52,-312.65 -6.97,-84.2 3.18,-303.79 -0.1,-413.19 -0.69,-22.97 -3.84,-21.96 -4.98,-43.07l0.02 -1821.37c0,-64.24 4.85,-149.53 -0.25,-211.2 -10.47,-126.5 -4.56,-274.19 -4.56,-408.73 0,-409.37 -15.96,-829.41 -14.3,-1235.17 0.15,-35.29 -5.02,-56.57 -4.93,-96 0.54,-238.94 -14.41,-489.17 -14.41,-720.86 -56.37,-37.75 -171.78,-80.74 -269.04,-86.5l-41.26 0c-59.59,3.98 -106.38,26.89 -118.14,80.98 -2.13,9.79 -3.41,21.48 -4.08,34.43l0 60.12c1.43,42.31 4.8,87.57 4.8,122.43 0,75.29 0,150.58 0,225.87 0,79.4 4.81,141.67 4.81,221.06 0,75.29 0,150.58 0,225.87 0,77.97 4.8,141.12 4.8,221.06 0,447.57 14.42,905.87 14.42,1350.4 0,592.95 19.22,1204.41 19.22,1797.36 0,442.87 14.42,899.75 14.42,1345.56 0,79.93 4.81,143.79 4.81,221.06 0,76.89 0,153.78 0,230.67 0,79.4 4.81,141.67 4.81,221.06 0,73.04 -3.18,267.38 4.1,332.3 5.7,50.79 -11.48,64.72 15.62,99.71 29.85,38.53 81.96,82.2 148.47,82.2z"
                  }
                }),
                _c("path", {
                  staticClass: "fil0 str0",
                  style: [
                    { stroke: _vm.borderColor },
                    { fill: _vm.fillColor },
                    { "stroke-width": _vm.borderWidth + "em !important" }
                  ],
                  attrs: {
                    d:
                      "M1423.11 1082.74l0 1696.41c0,91.54 122.23,192.22 225.87,192.22 62.37,0 107.22,0.36 165,-32.02 34.7,-19.45 61.26,-41.02 74.93,-78.86 13.93,-38.56 5.15,-241.86 5.15,-302.4 0,-207.37 4.81,-398.99 4.81,-605.52 0,-203.44 0.05,-406.88 0,-610.32 -0.04,-131.23 37.68,-251.19 -107.64,-339.29 -176.33,-106.91 -368.13,49.5 -368.13,79.79z"
                  }
                })
              ])
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }