import { render, staticRenderFns } from "./feedback-dialog.vue?vue&type=template&id=641126b0&scoped=true&"
import script from "./feedback-dialog.vue?vue&type=script&lang=ts&"
export * from "./feedback-dialog.vue?vue&type=script&lang=ts&"
import style0 from "./feedback-dialog.vue?vue&type=style&index=0&id=641126b0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "641126b0",
  null
  
)

/* vuetify-loader */
import installComponents from "!D:/Workspaces/Evidence/main/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib'
import { VCard } from 'vuetify/lib'
import { VCardActions } from 'vuetify/lib'
import { VCardText } from 'vuetify/lib'
import { VCardTitle } from 'vuetify/lib'
import { VDialog } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VForm } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VSpacer } from 'vuetify/lib'
import { VTextField } from 'vuetify/lib'
import { VTextarea } from 'vuetify/lib'
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VDialog,VFlex,VForm,VLayout,VSpacer,VTextField,VTextarea})


/* hot reload */
if (module.hot) {
  var api = require("D:\\Workspaces\\Evidence\\main\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('641126b0', component.options)
    } else {
      api.reload('641126b0', component.options)
    }
    module.hot.accept("./feedback-dialog.vue?vue&type=template&id=641126b0&scoped=true&", function () {
      api.rerender('641126b0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/feedback-dialog.vue"
export default component.exports