var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "500px", light: "", persistent: "" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.cancelAction()
        }
      },
      model: {
        value: _vm.value,
        callback: function($$v) {
          _vm.value = $$v
        },
        expression: "value"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "headline" },
            [
              _vm._v("\n      Feedback\n      "),
              _c("v-flex", { attrs: { xs12: "" } }, [
                _c("small", [_vm._v("We genuinely love to hear from you")])
              ])
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-layout",
                { attrs: { wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          model: {
                            value: _vm.formValid,
                            callback: function($$v) {
                              _vm.formValid = $$v
                            },
                            expression: "formValid"
                          }
                        },
                        [
                          false && _vm.currentLogin
                            ? _c("v-text-field", {
                                attrs: {
                                  label: "Name",
                                  box: "",
                                  disabled: "",
                                  value: _vm.currentLogin.firstname
                                }
                              })
                            : _vm._e(),
                          !_vm.currentLogin
                            ? _c("v-text-field", {
                                attrs: {
                                  label: "Name",
                                  type: "firstname",
                                  required: "",
                                  rules: [_vm.rules.requiredIfNoCurrentLogin],
                                  box: ""
                                },
                                model: {
                                  value: _vm.feedbackData.fromName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.feedbackData, "fromName", $$v)
                                  },
                                  expression: "feedbackData.fromName"
                                }
                              })
                            : _vm._e(),
                          !_vm.currentLogin
                            ? _c("v-text-field", {
                                attrs: {
                                  label: "Email",
                                  type: "email",
                                  required: "",
                                  rules: [
                                    _vm.rules.requiredIfNoCurrentLogin,
                                    _vm.rules.emailIfNoCurrentLogin
                                  ],
                                  box: ""
                                },
                                model: {
                                  value: _vm.feedbackData.fromEmail,
                                  callback: function($$v) {
                                    _vm.$set(_vm.feedbackData, "fromEmail", $$v)
                                  },
                                  expression: "feedbackData.fromEmail"
                                }
                              })
                            : _vm._e(),
                          _c("v-text-field", {
                            attrs: {
                              label: "Subject",
                              required: "",
                              rules: [_vm.rules.required],
                              box: ""
                            },
                            model: {
                              value: _vm.feedbackData.subject,
                              callback: function($$v) {
                                _vm.$set(_vm.feedbackData, "subject", $$v)
                              },
                              expression: "feedbackData.subject"
                            }
                          }),
                          _c("v-textarea", {
                            attrs: {
                              label: "Message",
                              required: "",
                              rules: [_vm.rules.required],
                              box: ""
                            },
                            model: {
                              value: _vm.feedbackData.message,
                              callback: function($$v) {
                                _vm.$set(_vm.feedbackData, "message", $$v)
                              },
                              expression: "feedbackData.message"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-btn", {
                attrs: { color: "white", round: "", flat: "" },
                on: { click: _vm.switchMode }
              }),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", round: "", flat: "" },
                  on: { click: _vm.cancel }
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", round: "" },
                  on: { click: _vm.postFeedback }
                },
                [_vm._v("Send")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }