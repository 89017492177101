import Dexie from "dexie";
import * as models from "@/models";
// const db = new Dexie("myDb");
// db.version(1).stores({
//   orgs: "id,name,industry,city,loginIds "
// });

class localDb extends Dexie {
  // Declare implicit table properties.
  // (just to inform Typescript. Instanciated by Dexie in stores() method)
  orgs: Dexie.Table<models.org, number>; // number = type of the primkey
  industries: Dexie.Table<models.industry, number>; // number = type of the primkey
  languages: Dexie.Table<models.language, string>; // number = type of the primkey
  //...other tables goes here...

  constructor(id: number | string) {
    super(`mi1.db.${id}`);
    this.version(1).stores({
      orgs: "id,name,industry,city,loginIds ",
      industries: "id,parent,children,title,nodeLevel",
      languages: "code,name"
      //...other tables goes here...
    });
  }

  static clearDb(id: number | string) {
    let dbName = `mi1.db.${id}`;
    new Dexie(dbName).close();
    return Dexie.delete(dbName);
  }
}

export default localDb;
