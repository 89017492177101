


















import Vue from "vue";
export default {
  name: "m-tabs",
  props: {
    value: { required: true },
    tabs: { required: true },
    selectedClass: {
      required: false,
      type: String,
      default: "accent-glow accent-gradiant primary--text"
    },
    defaultClass: {
      required: false,
      type: String,
      default: "accent--text text--darken-1"
    }
  },
  computed: {
    currentTabTitle: function() {
      return this.tabs[this.value];
    }
  },
  methods: {
    selectTab: function(t: string) {
      this.$emit("input", this.tabs.indexOf(t));
      this.$emit("tab-selected", { index: this.tabs.indexOf(t), value: t });
    }
  }
};
