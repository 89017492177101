import axios from "axios";
import { G } from "@/globals";
import { AppInsights } from "applicationinsights-js";

export default class {
  private static get IsLocalhost(): boolean {
    return window.location.href.startsWith("http://localhost");
  }

  private static url =
    "https://prod-73.westeurope.logic.azure.com/workflows/c2a95423ee0e4649bfa20186ad101746/triggers/manual/paths/invoke/log?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=l_n12Ezi7AKytFq4a4Cv1p9tf603eTBufsb4nrhiPtw";

  public static logError(title: string, error: any): void {
    console.error({ title: title, error: error });
    let details =
      typeof error == "string" || error instanceof String
        ? {
            message: error
          }
        : error;

    if (G.data.currentLogin) {
      details._loginId = G.data.currentLogin.id;
      details._loginEmail = G.data.currentLogin.email;
    }

    let data = {
      serviceName: "Evidence",
      type: "exception",
      title: title ? title : "",
      details: details
    };

    axios.post(this.url, data);

    this.aiLogException(error);
    // axios.post(this.url, data).finally(() => {
    //   return;
    // });
  }

  public static logDevError(title: string, error: any): void {
    if (!this.IsLocalhost) return;
    this.logError(title, error);
  }

  private static aiLogException(error: any): void {
    let ai = AppInsights;
    // ai.queue.push(() => {
    //   ai.context.addTelemetryInitializer((envelope: any) => {
    //     envelope.tags["ai.cloud.role"] = "Evidence";
    //     // envelope.tags["ai.cloud.roleInstance"] = "inssss";
    //   });
    // });
    ai.trackException(error);
    // AppInsights.trackEvent(
    //   "Vue App Started",
    //   {
    //     /*prop1: "prop1", prop2: "prop2" */
    //   },
    //   {
    //     /*measurement1: 1*/
    //   }
    // );
  }

  public static logAction(title: string, action: any): void {
    console.info({ title: title, action: action });
    let data = {
      serviceName: "Evidence",
      type: "action",
      title: title ? title : "",
      details: action ? action : {}
    };

    axios.post(this.url, data).finally(() => {
      return;
    });
  }

  public static logDevAction(title: string, action: any): void {
    if (!this.IsLocalhost) return;
    this.logAction(title, action);
  }
}
