




































































import Vue from "vue";
import { Tracking } from "@/code/tracking";
import { G, rules } from "@/globals";
import * as models from "@/models";
import { feedback } from "@/code/api.feedback";

export default {
  name: "feedbackDialog",
  props: ["value"],
  computed: {
    currentLogin: (): models.login => G.data.currentLogin
  },
  data() {
    return {
      feedbackData: {
        fromName: null,
        fromEmail: null,
        subject: null,
        message: null
      },
      rules: {
        required: rules.required,
        requiredIfNoCurrentLogin: G.data.currentLogin
          ? () => true
          : rules.required,
        emailIfNoCurrentLogin: G.data.currentLogin ? () => true : rules.email
      },
      formValid: false
    };
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    switchMode: function() {
      G.switchMode();
    },
    cancel: function() {
      let form: any = this.$refs.form;
      form.reset();
      this.$emit("input", false);
    },
    postFeedback: function() {
      let form: any = this.$refs.form;

      let valid = form.validate();
      if (!valid) return;

      G.showLoading("Sending...", true);

      let cl = G.data.currentLogin;

      feedback
        .post({
          subject: this.feedbackData.subject,
          message: this.feedbackData.message,
          name: cl ? cl.firstname : this.feedbackData.fromName,
          email: cl ? cl.email : this.feedbackData.fromEmail,
          id: cl ? cl.id : null
        })
        .then(() => {
          this.$emit("input", false);
          G.showSnackbar(
            "We got it, and will come back to you soon. Thank you.",
            "info"
          );

          this.feedbackData = {
            fromName: null,
            fromEmail: null,
            subject: null,
            message: null
          };
        })
        .catch(err => {
          G.log.error("PostFeedback", {
            error: err
            // id: this.qsId,
            // key: this.qsKey
          });
        })
        .finally(() => {
          G.hideLoading();
        });
    }
  }
};
