var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        fullscreen: "",
        "hide-overlay": "",
        xtransition: "dialog-bottom-transition",
        transition: "none",
        light: "",
        persistent: ""
      },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.cancelAction()
        }
      },
      model: {
        value: _vm.value,
        callback: function($$v) {
          _vm.value = $$v
        },
        expression: "value"
      }
    },
    [
      _c(
        "v-container",
        {
          staticClass: "xbg-sk",
          staticStyle: { padding: "15px", background: "white" },
          attrs: { fluid: "", "fill-height": "" }
        },
        [
          _c(
            "v-layout",
            {
              attrs: {
                column: "",
                "align-stretch": "",
                "justify-space-between": ""
              }
            },
            [
              _c("h1", { staticClass: "text-xs-center white--text" }, [
                _vm._v(_vm._s(_vm.title))
              ]),
              _c(
                "div",
                { attrs: { xstyle: "flex: 1;" } },
                [
                  _vm.isc
                    ? _c(
                        "v-window",
                        {
                          staticClass: "fill-height",
                          model: {
                            value: _vm.currentSlide,
                            callback: function($$v) {
                              _vm.currentSlide = $$v
                            },
                            expression: "currentSlide"
                          }
                        },
                        _vm._l(_vm.isc.slides, function(slide, idx) {
                          return _c(
                            "v-window-item",
                            { key: idx, staticClass: "xfill-height" },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "text-xs-center",
                                  attrs: {
                                    color: "transparent",
                                    flat: "",
                                    xheight: "200"
                                  }
                                },
                                [
                                  _c("v-img", {
                                    staticClass: "xelevation-9 mb-4",
                                    staticStyle: {
                                      "max-width": "80%",
                                      margin: "auto"
                                    },
                                    attrs: {
                                      rel: "preload",
                                      src: slide.mediaUrl
                                    }
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "primary--text headline text-xs-center",
                                      attrs: { "primary-title": "" }
                                    },
                                    [_vm._v(_vm._s(slide.headline))]
                                  ),
                                  _c(
                                    "v-card-text",
                                    { staticClass: "primary--text" },
                                    [_vm._v(_vm._s(slide.content))]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { staticStyle: { flex: "0" } },
                        [
                          !_vm.nextVisible
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    fab: "",
                                    flat: "",
                                    small: "",
                                    color: "grey lighten-2"
                                  },
                                  on: { click: _vm.restart }
                                },
                                [_c("v-icon", [_vm._v("refresh")])],
                                1
                              )
                            : _vm._e(),
                          _vm.nextVisible
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    fab: "",
                                    flat: "",
                                    small: "",
                                    color: "grey lighten-2"
                                  },
                                  on: { click: _vm.close }
                                },
                                [_c("v-icon", [_vm._v("close")])],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        [
                          _c(
                            "v-item-group",
                            {
                              staticClass: "text-xs-center",
                              attrs: { mandatory: "" },
                              model: {
                                value: _vm.currentSlide,
                                callback: function($$v) {
                                  _vm.currentSlide = $$v
                                },
                                expression: "currentSlide"
                              }
                            },
                            _vm._l(_vm.slidesCount, function(n) {
                              return _c("v-item", {
                                key: "btn-" + n,
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var active = ref.active
                                        var toggle = ref.toggle
                                        return _c(
                                          "v-btn",
                                          {
                                            staticStyle: { margin: "10px 3px" },
                                            attrs: {
                                              small: "",
                                              "input-value": active,
                                              icon: "",
                                              flat: "",
                                              color: active
                                                ? "accent darken-2"
                                                : "accent"
                                            },
                                            on: { click: toggle }
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { small: "" } },
                                              [_vm._v("fiber_manual_record")]
                                            )
                                          ],
                                          1
                                        )
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticStyle: { flex: "0" } },
                        [
                          _vm.nextVisible
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    fab: "",
                                    small: "",
                                    color: "primary"
                                  },
                                  on: { click: _vm.next }
                                },
                                [_c("v-icon", [_vm._v("arrow_forward_ios")])],
                                1
                              )
                            : _vm._e(),
                          !_vm.nextVisible
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    fab: "",
                                    small: "",
                                    color: "primary"
                                  },
                                  on: { click: _vm.close }
                                },
                                [_c("v-icon", [_vm._v("check")])],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }