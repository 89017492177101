import { server } from "@/code/server";
import * as models from "@/models";
import { G } from "@/globals";
import { Tracking } from "@/code/tracking";

export class accounts {
  //#region New Registration Cycle
  static getLoginForActivation = (id: number, key: string) => {
    return server.Post<models.login>("accounts/getLoginForActivation", {
      id,
      key
    });
  };
  static quickRegister = (
    email: string,
    firstname: string,
    isSeeker: boolean
  ) => {
    return server.Post<models.login>("accounts/quickRegister", {
      firstname,
      email,
      isSeeker
    });
  };

  static activateSeeker = (params: {
    id: number;
    key: string;
    firstname: string;
    lastname: string;
    password: string;
    cityId: string;
    headline: string;
    industryId: number;
    currentlyOpen: boolean;
    jobTypes: string[];
    languages: string[];
  }) => {
    return server
      .Post<models.login>("accounts/activateSeeker", params)
      .then(async lgn => {
        G.setCurrentLogin(lgn);
        Tracking.userId.set(lgn.id);
        return lgn;
      });
  };
  static activateRecruier = (params: {
    id: number;
    key: string;
    firstname: string;
    lastname: string;
    password: string;
    cityId: string;
    organizationName: string;
    industryId: number;
  }) => {
    return server
      .Post<models.login>("accounts/activateRecruiter", params)
      .then(async lgn => {
        G.setCurrentLogin(lgn);
        Tracking.userId.set(lgn.id);
        return lgn;
      });
  };

  //#endregion

  //#region Old Registration Cycle

  static register = (
    email: string,
    password: string,
    firstname: string,
    lastname: string
  ) => {
    return server.Post<models.login>("accounts/register", {
      email: email,
      password: password,
      firstname: firstname,
      lastname: lastname
    });
  };
  //#endregion

  //#region Password Reset
  static requestPasswordReset = async (email: string, lastname: string) => {
    return server.Post<boolean>("accounts/requestPasswordReset", {
      email,
      lastname
    });
  };

  static resetPassword = async (
    id: number,
    key: string,
    newPassword: string
  ) => {
    return server
      .Post<models.login>("accounts/resetPassword", {
        id,
        key,
        newPassword
      })
      .then(async lgn => {
        let isAutoSeekerMode = lgn.orgs.length == 0;
        G.setSeekerMode(isAutoSeekerMode, false);
        return accounts.processLogin(lgn);
      });
  };
  //#endregion

  //#region Login / Log Out
  static login = async (email: string, password: string) => {
    return server
      .Post<models.login>("accounts/login", {
        email: email,
        password: password
      })
      .then(async lgn => {
        let isAutoSeekerMode = lgn.orgs.length == 0;
        G.setSeekerMode(isAutoSeekerMode, false);
        return accounts.processLogin(lgn);
      });
  };

  static loginCurrentUser = async () => {
    return server
      .Post<models.login>("accounts/loginByToken", {}, true)
      .then(async lgn => accounts.processLogin(lgn))
      .catch(err => {
        G.showSnackbar(err, "error");
        G.log.error("api.accounts.loginCurrentUser", err);
        return accounts.logout();
      })
      .finally(() => {
        G.data.firstLoading = false;
      });
  };

  private static processLogin = async (lgn: models.login) => {
    if (lgn.seekerProfile)
      lgn.seekerProfile = new models.seekerProfileExtended(lgn.seekerProfile);
    G.setCurrentLogin(lgn);
    // let isAutoSeekerMode = ;
    if (lgn.settings.length == 0)
      G.setSeekerMode(lgn.orgs.length == 0, null, true);
    accounts.setLocalSettings(lgn.settings);
    Tracking.userId.set(lgn.id);
    G.localDb.db().isOpen;
    G.newMessagesWatch.start();
    G.initNotificationsIfSupported();
    return lgn;
  };

  static logout = () => {
    try {
      G.topLevelVue.$router.push({ name: "Login" });
      Tracking.userId.clear();
      G.newMessagesWatch.stop();
      G.setSeekerMode(true, G.topLevelVue);
      for (var key in localStorage) {
        if (key.startsWith("__ls__")) localStorage.removeItem(key);
      }
      if (G.data.currentLogin && G.data.currentLogin.id)
        G.localDb.clearDb(G.data.currentLogin.id);
    } catch {
    } finally {
      G.setCurrentLogin(null);
      G.hideLoading();
      return Promise.resolve();
    }
  };
  //#endregion

  //#region Settings

  private static setRemoteSetting = (name: string, value: string) => {
    return server.Post<models.loginSetting>(
      "accounts/setSetting",
      {
        name: name,
        value: value
      },
      true
    );
  };

  // private static getRemoteSettings = () => {
  //   return server
  //     .Post<models.loginSetting[]>("accounts/getSettings", {}, true)
  //     .then(lss => {
  //       accounts.setLocalSettings(lss);
  //     });
  // };

  private static setLocalSettings(lss: models.loginSetting[]): void {
    lss.forEach(ls => {
      accounts.setLocalSetting(ls);
    });
  }
  private static setLocalSetting(ls: models.loginSetting): void {
    localStorage.setItem("__ls__" + ls.name.toLowerCase(), ls.value);
  }
  private static _getLocalSetting(name: string): string {
    return localStorage.getItem("__ls__" + name.toLowerCase());
  }
  private static getLocalSettingString(
    name: string,
    defaultValue: string
  ): string {
    let value = this._getLocalSetting(name);
    return value || defaultValue;
  }
  private static getLocalSettingNumber(
    name: string,
    defaultValue: number
  ): number {
    let value = this._getLocalSetting(name);
    if (value) return parseInt(value);
    else return defaultValue;
  }
  private static getLocalSettingBoolean(
    name: string,
    defaultValue: boolean
  ): boolean {
    let value = this._getLocalSetting(name);
    if (value) return value == "true";
    else return defaultValue;
  }
  // Explicit Settings -------------------------------
  static get isSeekerMode(): boolean {
    //Default Value
    let defaultValue: boolean = true;
    if (G.data.currentLogin)
      defaultValue = G.data.currentLogin.orgs.length == 0;

    console.info(accounts.getLocalSettingBoolean("isSeekerMode", defaultValue));
    return accounts.getLocalSettingBoolean("isSeekerMode", defaultValue);
  }
  static set isSeekerMode(value: boolean) {
    if (G.data.currentLogin) {
      accounts
        .setRemoteSetting("isSeekerMode", String(value))
        .then(ls => {
          accounts.setLocalSetting(ls);
        })
        .catch(err => {
          this.setLocalSetting({
            name: "isSeekerMode",
            value: value ? "true" : "false"
          });
          G.log.error("api.accounts.setSeekerMode", err);
        })
        .finally(() => {});
    } else {
      this.setLocalSetting({
        name: "isSeekerMode",
        value: value ? "true" : "false"
      });
    }
  }

  static get currentOrgId(): number {
    let result = accounts.getLocalSettingNumber("currentOrgId", null);
    let cl = G.data.currentLogin;
    if (result) {
      return result;
    } else if (cl && cl.orgs && cl.orgs.length == 1) {
      return G.data.currentLogin.orgs[0].id;
    } else {
      return null;
    }
  }

  static set currentOrgId(value: number) {
    accounts.setRemoteSetting("currentOrgId", value.toString()).then(ls => {
      accounts.setLocalSetting(ls);
    });
  }

  static get currentOrg(): models.org {
    let result = accounts.getLocalSettingNumber("currentOrgId", null);
    let cl = G.data.currentLogin;
    if (result) {
      return G.data.currentLogin.orgs.find(org => org.id == result);
    } else if (cl && cl.orgs && cl.orgs.length == 1) {
      return cl.orgs[0];
    } else {
      return null;
    }
  }
  static get miniSeekerCard(): boolean {
    return accounts.getLocalSettingBoolean("miniSeekerCard", true);
  }

  static set miniSeekerCard(value: boolean) {
    accounts
      .setRemoteSetting("miniSeekerCard", value ? "true" : "false")
      .then(ls => {
        accounts.setLocalSetting(ls);
      });
  }

  //#endregion
}
