








































import Vue from "vue";
// @vuese
// Reusable page toolbar with advanced features
export default {
  name: "mToolbar",
  props: {
    // Toolbar title
    title: {
      type: String,
      default: "MeInOne",
      required: true
    },
    // Show/Hide app navigation drawer
    menuButton: {
      type: Boolean,
      default: true,
      required: false
    },
    // Show/Hide app home link button
    homeButton: {
      type: Boolean,
      default: false,
      required: false
    },
    // Show/Hide back button
    backButton: {
      type: Boolean,
      default: false,
      required: false
    },
    // Ignore
    preventDefaultNavBackAction: {
      type: Boolean,
      default: false,
      required: false
    },
    // Ignore
    textColor: {
      type: String,
      default: "primary--text text--lighten-2",
      required: false
    }
  },

  data() {
    return {};
  },
  watch: {
    title: function(newValue) {
      document.title = this.title;
    }
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    // xxxxx.
    toggleNavDrawer: function() {
      this.G.navDrawerVisible = !this.G.navDrawerVisible;
      this.$emit("click:nav-drawer");
    },
    // Handles Back button click.
    goHome: function() {
      this.$router.push({ name: "Home" });
      this.$emit("click:home");
    },
    // Handles Back button click.
    navBack: function() {
      this.$emit("click:nav-back");
      if (this.preventDefaultNavBackAction) return;
      if (window.history.length <= 1) {
        // There is no back, go home
        this.$router.push({ name: "Home" });
        this.$emit("click:home");
      } else {
        // There is back, let's go ;)
        this.$router.go(-1);
        console.log(this.$router);
      }
    }
  }
};
