












































































import Vue from "vue";
import { Tracking } from "@/code/tracking";
import * as models from "@/models";
import { info } from "@/code/api.info";

export default {
  name: "infoSlides",
  props: {
    value: {
      required: false,
      default: false
    },
    title: {
      required: false,
      default: null,
      type: String
    },
    collectionId: {
      required: false,
      default: null,
      type: Number
    }
  },
  computed: {
    slidesCount: function() {
      if (!this.isc) return 0;
      return this.isc.slides.length;
    },
    nextVisible: function() {
      return this.currentSlide != this.slidesCount - 1;
    }
  },
  data() {
    return {
      isc: null as models.infoSlidesCollection,
      currentSlide: 0
    };
  },
  watch: {
    collectionId: function(newId: number, oldId: number) {},
    value: function(newValue: boolean) {
      if (!newValue) return;
      if (
        !this.collectionId ||
        (!!this.isc && this.isc.id == this.collectionId)
      )
        return;
      //----
      this.isc = null;
      info.getInfoSlidesCollection(this.collectionId).then(isc => {
        this.currentSlide = 0;
        this.isc = isc;
        // this.currentSlide = 0;
      });
    }
  },
  created() {},
  mounted() {},
  methods: {
    close: function() {
      this.$emit("input", false);
      // this.currentSlide = 0;
      setTimeout(() => {
        // this.currentSlide = 0;
      }, 500);
    },
    next() {
      this.currentSlide =
        this.currentSlide + 1 === this.length ? 0 : this.currentSlide + 1;
    },
    prev() {
      this.currentSlide =
        this.currentSlide - 1 < 0 ? this.length - 1 : this.currentSlide - 1;
    },
    restart() {
      this.currentSlide = 0;
    }
  }
};
