





































import Vue from "vue";
export default {
  name: "logo",
  props: {
    cssClass: {
      //type: Boolean,
      default: null,
      required: false
    },
    cssStyle: {
      //type: Boolean,
      default: null,
      required: false
    },
    shadow: {
      type: Number,
      default: 4,
      required: false
    },
    borderWidth: {
      type: Number,
      default: 3,
      required: false
    },

    borderColor: {
      type: String,
      default: "white",
      required: false
    },
    fillColor: {
      type: String,
      default: "white",
      required: false
    },
    animate: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  data() {
    return {};
  },
  watch: {},
  computed: {},
  created() {},
  mounted() {},
  methods: {}
};
