
































import Vue from "vue";
// @vuese
// Reusable page with advanced features
export default {
  name: "mPage",
  props: {
    isParentChild: {
      type: Boolean,
      default: false,
      required: false
    },
    parentClass: {
      type: String,
      default: null,
      required: false
    }
  },

  data() {
    return {
      childHasContent: false
    };
  },
  watch: {},
  computed: {
    toolbarVisible: function(): boolean {
      if (!this.$slots.toolbar) return false;
      if (!this.childHasContent) {
        return true;
      } else if (
        this.childHasContent &&
        this.isParentChild &&
        this.$vuetify.breakpoint.smAndUp
      ) {
        return true;
      }
      return false;
    }
  },
  created() {},
  mounted() {},
  methods: {
    childRouteChanged: function(created: boolean) {
      console.log("crc: ", created);

      this.childHasContent = created;
      this.$emit("child-state-changed", created);
    }
  }
};
