import { server } from "@/code/server";
import * as models from "@/models";

export class info {
  // View
  static getInfoSlidesCollection = (collectionId: number) => {
    return server.Post<models.infoSlidesCollection>(
      "info/getInfoSlidesCollection",
      { id: collectionId },
      false
    );
  };
}
