import Vue from "vue";
import Router from "vue-router";
import { RouteConfig } from "vue-router";
import Login from "./views/accounts/Login.vue";

Vue.use(Router);
export interface iViewSettings {
  toolbar: boolean;
  bottomNav: boolean;
  toolbarSideButton?: {
    style: "back" | "home" | "nav-menu";
  };
  backButtonUrl?: string;
  feedbackFabVisible: boolean;
  dark?: boolean;
  backButtonCallback?: any;
}

export enum ViewAccessType {
  /** With or without a logged in user, seeker or recruiter */
  Everyone = "Everyone",
  // SeekersOnly,
  // RecruiterOnly,
  /**Logged in seekers or recruiter */
  AllLoggedIn = "AllLoggedIn",
  /**No current login */
  LoggedOut = "LoggedOut"
}

//#region Routes
let routesAccountsAndLogin: RouteConfig[] = [
  {
    name: "Login",
    path: "/login",
    meta: {
      accessType: ViewAccessType.LoggedOut,
      viewSettings: <iViewSettings>{
        // toolbar: false,
        // bottomNav: false,
        // feedbackFabVisible: true,
        dark: true
      }
    },
    component: Login
  },
  {
    name: "Accounts_Create",
    path: "/accounts/create",
    meta: {
      accessType: ViewAccessType.LoggedOut,
      viewSettings: <iViewSettings>{
        // toolbar: false,
        // bottomNav: false,
        // feedbackFabVisible: true,
        dark: true
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "accounts" */ "./views/accounts/Account.Create.vue"
      )
  },
  {
    name: "Accounts_Activate",
    path: "/accounts/activate",
    meta: {
      accessType: ViewAccessType.LoggedOut,
      viewSettings: <iViewSettings>{
        // toolbar: false,
        // bottomNav: false,
        dark: true
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "accounts" */ "./views/accounts/Account.Activate.vue"
      )
  },
  {
    name: "Accounts_ResetPassword",
    path: "/accounts/reset",
    meta: {
      accessType: ViewAccessType.LoggedOut,
      viewSettings: <iViewSettings>{
        // toolbar: false,
        // bottomNav: false,
        dark: true
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "accounts" */ "./views/accounts/Account.ResetPassword.vue"
      )
  }
];
let routeMessages: RouteConfig = {
  name: "Messages",
  path: "messages",
  meta: {
    accessType: ViewAccessType.AllLoggedIn
  },

  component: () =>
    import(/* webpackChunkName: "messages" */ "./pages/messages/Messages.vue"),
  children: [
    {
      name: "Messages__Message",
      path: "message/:id?",
      meta: {
        accessType: ViewAccessType.AllLoggedIn
      },
      props: true,

      component: () =>
        import(
          /* webpackChunkName: "messages" */ "./pages/messages/Message.vue"
        )
    }
  ]
};
let routeOrgsDiscover: RouteConfig = {
  name: "Orgs_Discover",
  path: "orgs/discover",
  meta: {
    accessType: ViewAccessType.AllLoggedIn
  },
  component: () =>
    import(/* webpackChunkName: "orgs" */ "./pages/orgs/Discover.vue"),
  children: [
    {
      name: "Orgs_Discover__SeekerProfile",
      path: "seeker/@:id",
      meta: {
        accessType: ViewAccessType.Everyone
      },
      props: true,
      component: () =>
        import(/* webpackChunkName: "orgs" */ "./pages/orgs/SeekerProfile.vue")
    }
  ]
};
let routeOrgsJobPosts: RouteConfig = {
  name: "Orgs_JobPosts",
  path: "orgs/jobposts",
  meta: {
    accessType: ViewAccessType.AllLoggedIn
  },
  component: () =>
    import(/* webpackChunkName: "orgs" */ "./pages/orgs/JobPosts.vue"),
  children: [
    {
      name: "Orgs_JobPosts__JobPost",
      path: "jobpost/:id?",
      meta: {
        accessType: ViewAccessType.AllLoggedIn
      },
      props: true,
      component: () =>
        import(/* webpackChunkName: "orgs" */ "./pages/orgs/JobPost.vue") //JobPost
    },
    {
      name: "Orgs_JobPosts__NewJobPost",
      path: "newjobpost",
      meta: {
        accessType: ViewAccessType.AllLoggedIn
        // accessType: ViewAccessType.RecruiterOnly,
      },
      props: true,
      component: () =>
        import(/* webpackChunkName: "orgs" */ "./pages/orgs/NewJobPost.vue")
    }
  ]
};
let routeSeekersDiscover: RouteConfig = {
  name: "Seekers_Discover",
  path: "seekers/discover",
  meta: {
    accessType: ViewAccessType.AllLoggedIn
  },
  component: () =>
    import(/* webpackChunkName: "seekers" */ "./pages/seekers/Discover.vue"),
  children: [
    // {
    //   name: "Orgs_Discover__SeekerProfile",
    //   path: "sadfsafaf/@:id",
    //   meta: {
    //     accessType: ViewAccessType.Everyone
    //   },
    //   props: true,
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "seekers" */ "./pages/orgs/SeekerProfile.vue"
    //     )
    // }
  ]
};
let routeSeekersMyProfile: RouteConfig = {
  name: "Seekers_MyProfile",
  path: "seekers/myprofile",
  meta: {
    accessType: ViewAccessType.AllLoggedIn
  },
  component: () =>
    import(/* webpackChunkName: "seekers" */ "./pages/seekers/MyProfile.vue"),
  children: [
    // {
    //   name: "Orgs_Discover__SeekerProfile",
    //   path: "asdasd@:id",
    //   meta: {
    //     accessType: ViewAccessType.Everyone
    //   },
    //   props: true,
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "seekers" */ "./pages/orgs/SeekerProfile.vue"
    //     )
    // }
  ]
};
let routeHome: RouteConfig = {
  name: "Home",
  path: "/",
  meta: {
    accessType: ViewAccessType.AllLoggedIn
  },
  component: () => import(/* webpackChunkName: "next" */ "./pages/Home.vue"),
  children: [
    routeMessages,
    routeOrgsDiscover,
    routeOrgsJobPosts,
    routeSeekersDiscover,
    routeSeekersMyProfile
  ]
};
let routesPublic: RouteConfig[] = [
  {
    name: "Seeker_Profile",
    path: "/seeker/@:id",
    meta: {
      accessType: ViewAccessType.Everyone,
      viewSettings: <iViewSettings>{
        toolbar: false,
        bottomNav: true,
        toolbarSideButton: { style: "back" }
      }
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "public" */ "./pages/public/SeekerProfile.vue"
      )
  },
  {
    name: "Public_JobPost",
    path: "/job/@:id",
    meta: {
      accessType: ViewAccessType.Everyone,
      viewSettings: <iViewSettings>{
        toolbar: true,
        bottomNav: true,
        toolbarSideButton: { style: "back" }
      }
    },
    props: true,
    component: () =>
      import(/* webpackChunkName: "public" */ "./pages/public/JobPost.vue")
  }
];
//#endregion

export const router = new Router({
  mode: "history",
  routes: [
    ...routesAccountsAndLogin,
    routeHome,
    ...routesPublic,
    {
      name: "TESTS",
      path: "/tests",
      meta: {
        accessType: ViewAccessType.Everyone,
        viewSettings: <iViewSettings>{
          toolbar: false,
          bottomNav: false,
          dark: true
        }
      },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "tests" */ "./pages/tests.vue")
    },

    {
      path: "/index.html",
      redirect: "/"
    }
    // {
    //   // will match everything
    //   path: "*",
    //   redirect: "/"
    // }

    //Org.Register
  ]
});

export default router;
