import isEqual from "lodash.isequal";
import moment from "moment";

//#region Grouping

export interface groupedItem<tKey, tItem> {
  key: tKey;
  items: tItem[];
}

export class grouping {
  static groupBy<tKey, tItem>(
    items: tItem[],
    keyFunction: (item: tItem) => tKey,
    keySortFunction?: (keyA: tKey, keyB: tKey) => number,
    itemSortFunction?: (itemA: tItem, itemB: tItem) => number
  ) {
    let result: groupedItem<tKey, tItem>[] = [];

    items.forEach(function (item) {
      let key = keyFunction(item);

      // Key Exists?
      if (!result.find(gi => isEqual(gi.key, key)))
        result.push({ key: key, items: [] });

      result.find(gi => isEqual(gi.key, key)).items.push(item);
    });

    if (keySortFunction)
      result = result.sort((a, b) => keySortFunction(a.key, b.key));

    if (itemSortFunction)
      result.forEach(grp => {
        grp.items = grp.items.sort((a, b) => itemSortFunction(a, b));
      });

    return result;
  }
}
//#endregion

//#region Messaging
export interface thread {
  organization: org;
  seeker: seekerProfile;

  id: number;
  subject: string;
  creationDate: string;
  lastMessageDate: string;
  totalMessagesCount: number;
  newMessagesCount: number;

  fromHeader: string;
  fromSubHeader: string;

  messages: message[];
  pagedMessages: paged<message>;
}
export interface message {
  threadId: number;
  id: number;
  creationDate: string;
  text: string;
  isFromSeeker: boolean;
  isNewToMe: boolean;
  isMine: boolean;
  video?: video;
}

//#endregion

//#region Info
export interface infoSlidesCollection {
  id: number;
  name: string;
  slides: infoSlide[];
}
export interface infoSlide {
  id: number;
  headline: string;
  content: string;
  orderId: number;
  mediaUrl: string;
  mediaIsVideo: boolean;
}
//#endregion

//#region Seeker Labels

export interface seekerLabel {
  organization: org;
  seekerProfile: seekerProfile;
  jobPost?: jobPost;

  id: number;
  content: string;
  creationDate: string;
}

export class seekerLabelExtended implements seekerLabel {
  organization: org;
  seekerProfile: seekerProfile;
  jobPost?: jobPost;

  id: number;
  content: string;
  creationDate: string;

  constructor(seekerLabel: seekerLabel) {
    Object.assign(this, seekerLabel);
  }
  static extend(sls: seekerLabel[], sort?: boolean): seekerLabelExtended[] {
    let result = sls.map(sl => new seekerLabelExtended(sl));
    if (sort) result = seekerLabelExtended.sort(result);
    return result;
  }

  get creationDateMoment(): moment.Moment {
    return moment(this.creationDate);
  }

  get isPredefined(): boolean {
    return predefinedLabels.isPredefined(this.content);
  }

  get orderIndex(): number {
    switch (this.content) {
      case predefinedLabels.labels.Applied.content:
        return 5;
      case predefinedLabels.labels.Bookmarked.content:
        return 15;
      case predefinedLabels.labels.Shortlisted.content:
        return 10;
      case predefinedLabels.labels.Saved.content:
        return 20;
      case predefinedLabels.labels.Ignored.content:
        return 30;
      default:
        return 100;
    }
  }

  get contentAttributes() {
    return predefinedLabels.getAttributes(this.content);
  }
  static sort(slx: seekerLabelExtended[]): seekerLabelExtended[] {
    return slx.sort(seekerLabelExtended.labelSortFunction);
  }
  static labelSortFunction = (
    a: { orderIndex: number; content: string },
    b: { orderIndex: number; content: string }
  ) => {
    if (a.orderIndex != b.orderIndex) {
      //sort by order
      return a.orderIndex < b.orderIndex ? -1 : 1;
    } else {
      //sort by content
      return a.content < b.content ? -1 : 1;
    }
    // true values first
    // a === b ? 0 : a ? -1 : 1
    // false values first
    // return (a === b)? 0 : a? 1 : -1;
  };
  static groupByLabelContent(slx: seekerLabelExtended[]) {
    if (!slx || slx.length == 0) return [];
    return grouping.groupBy(
      slx,
      sl => {
        return {
          content: sl.content,
          orderIndex: sl.orderIndex,
          contentAttributes: sl.contentAttributes
        };
      },
      seekerLabelExtended.labelSortFunction,
      seekerLabelExtended.labelSortFunction
    );
  }
}

export class predefinedLabels {
  static labels = {
    Saved: {
      content: "Saved",
      color: "success",
      icon: "favorite"
    },
    Applied: {
      content: "Applied",
      color: "primary",
      icon: "touch_app"
    },
    Ignored: {
      content: "Ignored",
      color: "warning",
      icon: "close"
    },
    Bookmarked: {
      content: "Bookmarked",
      color: "success",
      icon: "favorite"
    },
    Shortlisted: {
      content: "Shortlisted",
      color: "primary",
      icon: "playlist_add_check"
    }
  };
  static all = [
    predefinedLabels.labels.Applied.content,
    predefinedLabels.labels.Bookmarked.content,
    predefinedLabels.labels.Ignored.content,
    predefinedLabels.labels.Saved.content,
    predefinedLabels.labels.Shortlisted.content
  ];
  static seekerVisible = [
    predefinedLabels.labels.Applied.content,
    predefinedLabels.labels.Bookmarked.content
  ];
  static recruiterVisible = [
    predefinedLabels.labels.Applied.content,
    predefinedLabels.labels.Ignored.content,
    predefinedLabels.labels.Saved.content,
    predefinedLabels.labels.Shortlisted.content
  ];
  static isPredefined = (content: string): boolean => {
    return predefinedLabels.all.some(v => v == content);
  };
  static getAttributes = (content: string): { color: string; icon: string } => {
    let v = predefinedLabels.labels[content];
    if (!v) return { color: "default", icon: null };
    return { color: v.color, icon: v.icon };
  };
}

//#endregion

//#region Job Post
export interface jobPost {
  organization: org;
  city: city;
  industry: industry;

  id: number;
  title: string;
  description: string;

  isListed: boolean;

  creationDate: string;
  listingDate?: string;
  expiryDate?: string;

  context: {
    seeker?: jobPostSeekerContext;
    recruiter?: jobPostRecruiterContext;
  };
}
export interface jobPostSeekerContext {
  application?: seekerLabel;
  bookmarked?: seekerLabel;
}
export interface jobPostRecruiterContext {
  appliedCustomLabels?: string[];
  seekerLabels?: seekerLabel[];
  labelGroups?: { labelContent: string; seekerLabels: seekerLabel[] }[];
}
export class jobPostExtended implements jobPost {
  organization: org;
  city: city;
  industry: industry;
  id: number;
  title: string;
  description: string;
  isListed: boolean;
  creationDate: string;
  listingDate?: string;
  expiryDate?: string;
  context: {
    seeker?: jobPostSeekerContext;
    recruiter?: jobPostRecruiterContext;
  };

  public constructor(jobPost: jobPost) {
    Object.assign(this, jobPost);
  }
  get creationDateMoment(): moment.Moment {
    return moment(this.creationDate);
  }
  get listingDateMoment(): moment.Moment {
    return this.listingDate ? moment(this.listingDate) : null;
  }
  get expiryDateMoment(): moment.Moment {
    return this.expiryDate ? moment(this.expiryDate) : null;
  }
  get isExpired(): boolean {
    return this.isListed && moment().utc() > this.expiryDateMoment;
  }
  static sort(jps: jobPostExtended[]): jobPostExtended[] {
    return jps.sort((a, b) =>
      moment.utc(b.creationDate).diff(moment.utc(a.creationDate))
    );
  }
}
export enum jobPostStatusString {
  // all = "all",
  published = "published",
  notListed = "notlisted",
  expired = "expired"
}

export const jobPostStatusList = [
  { text: "Published", value: jobPostStatusString.published },
  { text: "Archived", value: jobPostStatusString.notListed },
  { text: "Expired", value: jobPostStatusString.expired }
];

//#endregion

//#region Countries / Cities
export interface country {
  code: string;
  name: string;
  nativeName: string;
  continent: string;
  capital: string;
  phoneKeys: string[];
  currencies: string[];
  languages: string[];
  // isActive: boolean;
  cities: city[];
}

export interface city {
  countryCode: string;
  id: string;
  name: string;
  geoLocation: geoLocation;
  // isActive: boolean;
}

export interface geoLocation {
  latitude: number;
  longitude: number;
}
//#endregion

//#region Seeker Profile & Video

export interface seekerProfile {
  id?: number;
  industry?: industry;
  city?: city;

  headline?: string;
  bio?: string;
  jobTypes?: string;

  firstname?: string;
  lastname?: string;
  fullname?: string;

  currentlyOpen?: boolean;

  seekerVideos?: seekerVideo[];

  languages?: language[];

  context?: {
    labels: seekerLabel[];
    threads: thread[];
  };
}

export class seekerProfileExtended implements seekerProfile {
  id?: number;
  industry?: industry;
  city?: city;

  headline?: string;
  jobTypes?: string;

  firstname?: string;
  lastname?: string;

  currentlyOpen?: boolean;

  seekerVideos?: seekerVideo[];

  languages?: language[];

  context?: {
    labels: seekerLabel[];
    threads: thread[];
  };

  public constructor(seekerProfile: seekerProfile) {
    Object.assign(this, seekerProfile);
  }

  hasLabel = (jpId: number, content: string): boolean => {
    return this.seekerLabelsEx.some(
      slx => slx.jobPost.id == jpId && slx.content == content
    );
  };

  get fullname(): string {
    return this.firstname + " " + this.lastname;
  }
  set fullname(val: string) {
    // DO NOT DELETE
  }
  get hasLabels(): boolean {
    return (
      !!this.context && !!this.context.labels && this.context.labels.length > 0
    );
  }

  get hasVideos(): boolean {
    return !!this.seekerVideos && this.seekerVideos.length > 0;
  }

  get hasThreads(): boolean {
    return (
      !!this.context &&
      !!this.context.threads &&
      this.context.threads.length > 0
    );
  }

  get threads(): thread[] {
    if (!this.hasThreads) return [];
    return this.context.threads;
  }

  get seekerLabelsEx(): seekerLabelExtended[] {
    if (!this.hasLabels) return [];
    return seekerLabelExtended.extend(this.context.labels, true);
  }

  get groupedSeekerLabelsEx() {
    if (!this.hasLabels) return [];
    return seekerLabelExtended.groupByLabelContent(
      seekerLabelExtended.extend(this.context.labels, true)
    );
  }
}

export interface seekerVideo {
  skrId: number;
  vidId: number;

  isPublic: boolean;
  isPrimary: boolean;
  order: number;

  video: video;
}

export interface video {
  vidId: number;
  vidUri: string;
  vidTitle: string;

  vidStatusId: videoStatus; // number;
  vidStatusTitle: string;

  vidThumbUriComputed: string;
}

export enum videoStatus {
  Undefined = 0,
  UploadedToStaging = 10,
  PickedUpByProcessor = 20,
  Validated = 30,
  ProcessingError = 199,
  Ready = 200
}
//#endregion

//#region Login
export interface login {
  id: number;
  email: string;
  firstname: string;
  lastname: string;
  token: string;

  settings: loginSetting[];
  orgs: org[];
  seekerProfile: seekerProfileExtended;
}

export interface loginSetting {
  name?: string;
  value?: string;
}
//#endregion

//#region Organization
export interface org {
  id: number;
  name: string;
  industry: industry;
  city: city;
  loginIds: number[];
}
//#endregion

//#region Misc
export interface language {
  code: string;
  name: string;
}
export interface industry {
  parent?: industry;
  children?: industry[];

  id: number;
  title: string;

  nodeLevel?: number;
}

export interface lookupItem {
  id: number;
  name: string;
}
export interface apiResult<t> {
  isResolved: boolean;
  body: t;
  rejectionReason: string;
}
export interface paged<t> {
  hasPrevious: boolean;
  hasNext: boolean;
  items: t[];
  skipped: number;
  taken: number;
}
//#endregion

//#region Internals
export enum loadMode {
  remote,
  localAndUpdate,
  local
}
//#endregion

//#region PushMessages
export interface pushMessage {
  data: {
    pmId: string;
    command?: string;
    notifyInBackground: string;
    notifyInForeground: string;
    image?: string;
    bodyHtml?: string;
    group?: string;
    iconName?: string;
  };
  notification?: {
    title: string;
    body: string;
    icon: string;
    click_action: string;
  };
  from: string;
  collapse_key: string;
}
export class pushMessageObject {
  constructor(pm: pushMessage) {
    this.pm = pm;
  }
  pm: pushMessage;

  get pmId(): number {
    return Number.parseInt(this.pm.data.pmId);
  }
  get notifyInBackground(): boolean {
    return this.pm.data.notifyInBackground == "true";
  }
  get notifyInForeground(): boolean {
    return this.pm.data.notifyInForeground == "true";
  }
  get hasNotification(): boolean {
    return !!this.pm.notification;
  }
  get command(): { [key: string]: any } {
    return !this.pm.data.command ? {} : JSON.parse(this.pm.data.command);
  }
}

export interface notification {
  id: number;
  creationDate: Date;
  readInAppDate: Date;
  command?: { [key: string]: any };
  notification: {
    title: string;
    body: string;
    bodyHtml?: string;
    iconUrl?: string;
    iconName?: string;
    imageUrl?: string;
    actionUrl?: string;
    group?: string;
  };
}
export interface localFcmToken {
  loginId: number;
  fcmToken: string;
}
//#endregion
