import { G, rules } from "@/globals";
import dynamicSplitView from "@/components/dynamic-split-view.vue";
import Vue from "vue";

export default Vue.extend({
  props: {
    // isChildView: {
    //   type: Boolean,
    //   default: false,
    //   required: false
    // }
  },
  data: () => ({
    G: G.data,
    globalRules: {
      required: rules.required,
      maxCounter: rules.maxCounter,
      passwordLength: rules.passwordLength,
      email: rules.email,
      stringLength: rules.stringLength
    }
  }),
  computed: {
    currentLogin: function () {
      return G.data.currentLogin;
    },
    isSeekerMode: () => G.data.isSeekerMode,
    dynamicGradiantBackground: function () {
      return G.data.isSeekerMode ? "bg-sk" : "bg-rc";
    },
    notificationPermissionStatus: function () {
      // granted: user has accepted the request
      // default: user has dismissed the notification permission popup by clicking on x
      // denied: user has denied the request.
      let win = <any>window;
      if (!win.Notification || !win.Notification.permission) return "default";
      let value = win.Notification.permission;
      return value;
    }
  },
  methods: {
    showInfoSlides: function (collectionId: number) {
      G.data.infoSlides.collectionId = collectionId;
      G.data.infoSlides.visible = true;
    },
    requestNotificationPermission: function () {
      return G.requestNotificationPermission();
    }
  },
  components: { dynamicSplitView }
});
