var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card-actions",
    [
      _vm._l(_vm.tabs, function(t) {
        return _c(
          "v-btn",
          {
            key: t,
            class:
              _vm.currentTabTitle == t ? _vm.selectedClass : _vm.defaultClass,
            attrs: {
              round: "",
              xsmall: "!$vuetify.breakpoint.smAndUp",
              flat: ""
            },
            on: {
              click: function($event) {
                return _vm.selectTab(t)
              }
            }
          },
          [_vm._v(_vm._s(t))]
        )
      }),
      _vm.$slots.afterSpacer ? _c("v-spacer") : _vm._e(),
      _vm._t("afterSpacer")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }