var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticStyle: { padding: "64px 32px" },
      attrs: { xfluid: "", "fill-height": "" }
    },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "", "xfill-height": "" } },
        [
          _c(
            "v-flex",
            {
              attrs: {
                "d-flex": "",
                xs10: "",
                "offset-xs1": "",
                sm8: "",
                "offset-sm2": "",
                md6: "",
                "offset-md3": ""
              }
            },
            [
              _c(
                "v-layout",
                {
                  attrs: {
                    column: "",
                    wrap: "",
                    "align-stretch": "",
                    "justify-space-between": ""
                  }
                },
                [
                  _c("div", { staticClass: "text-xs-center" }, [
                    _c("img", {
                      staticStyle: { xfloat: "right" },
                      attrs: { src: "img/logo-white-word.png", width: "200" }
                    }),
                    _c("h1", { staticClass: "fun-font text-center" }, [
                      _vm._v(
                        _vm._s(
                          _vm.signinView
                            ? "The world's video resume platform"
                            : "Reset Password"
                        )
                      )
                    ])
                  ]),
                  _vm.signinView
                    ? [
                        _c(
                          "div",
                          [
                            _c(
                              "v-form",
                              {
                                ref: "form",
                                model: {
                                  value: _vm.formValid,
                                  callback: function($$v) {
                                    _vm.formValid = $$v
                                  },
                                  expression: "formValid"
                                }
                              },
                              [
                                _c("br"),
                                _c("v-text-field", {
                                  staticStyle: {
                                    "xbackground-color": "'white'"
                                  },
                                  attrs: {
                                    label: "Login Email",
                                    type: "email",
                                    rules: [
                                      _vm.rules.required,
                                      _vm.rules.email,
                                      _vm.rules.maxCounter(150)
                                    ],
                                    maxlength: "150",
                                    placeholder: " ",
                                    color: "white"
                                  },
                                  on: {
                                    keyup: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.login($event)
                                    }
                                  },
                                  model: {
                                    value: _vm.email,
                                    callback: function($$v) {
                                      _vm.email = $$v
                                    },
                                    expression: "email"
                                  }
                                }),
                                _c("v-text-field", {
                                  attrs: {
                                    placeholder: " ",
                                    autocomplete: "off",
                                    label: "Password",
                                    type: "password",
                                    rules: [
                                      _vm.rules.required,
                                      _vm.rules.maxCounter(150)
                                    ],
                                    maxlength: "150",
                                    color: "white"
                                  },
                                  on: {
                                    keyup: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.login($event)
                                    }
                                  },
                                  model: {
                                    value: _vm.password,
                                    callback: function($$v) {
                                      _vm.password = $$v
                                    },
                                    expression: "password"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  block: "",
                                  large: "",
                                  loading: _vm.loggingIn,
                                  disabled: _vm.loggingIn,
                                  color: "accent",
                                  outline: "",
                                  round: ""
                                },
                                on: { click: _vm.login }
                              },
                              [
                                _vm._v(
                                  "\n              SIGN IN\n              "
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "text-xs-center" },
                              [
                                _c("br"),
                                _vm._v(
                                  "Don't have an account?\n              "
                                ),
                                _c(
                                  "router-link",
                                  { attrs: { to: "/accounts/create" } },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "white--text font-weight-bold"
                                      },
                                      [_vm._v("Sign Up")]
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  block: "",
                                  large: "",
                                  flat: "",
                                  color: "secondary",
                                  round: ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.setCurrentSubView(false)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n              Forgot My Password\n              "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    : [
                        _c(
                          "div",
                          [
                            !_vm.passwordReset.requested
                              ? _c(
                                  "v-form",
                                  {
                                    ref: "resetForm",
                                    model: {
                                      value: _vm.passwordReset.formValid,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.passwordReset,
                                          "formValid",
                                          $$v
                                        )
                                      },
                                      expression: "passwordReset.formValid"
                                    }
                                  },
                                  [
                                    _c("br"),
                                    _c("v-text-field", {
                                      attrs: {
                                        label: "Login Email",
                                        type: "email",
                                        rules: [
                                          _vm.rules.required,
                                          _vm.rules.email,
                                          _vm.rules.maxCounter(150)
                                        ],
                                        maxlength: "150",
                                        placeholder: " ",
                                        color: "white"
                                      },
                                      on: {
                                        keyup: function($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          return _vm.requestPasswordReset(
                                            $event
                                          )
                                        }
                                      },
                                      model: {
                                        value: _vm.email,
                                        callback: function($$v) {
                                          _vm.email = $$v
                                        },
                                        expression: "email"
                                      }
                                    }),
                                    _c("v-text-field", {
                                      attrs: {
                                        label: "Last Name",
                                        type: "lastName",
                                        rules: [_vm.rules.required],
                                        maxlength: "50",
                                        placeholder: " ",
                                        color: "white"
                                      },
                                      on: {
                                        keyup: function($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          return _vm.requestPasswordReset(
                                            $event
                                          )
                                        }
                                      },
                                      model: {
                                        value: _vm.passwordReset.lastName,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.passwordReset,
                                            "lastName",
                                            $$v
                                          )
                                        },
                                        expression: "passwordReset.lastName"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        !_vm.passwordReset.requested
                          ? _c(
                              "div",
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      block: "",
                                      large: "",
                                      loading: _vm.passwordReset.requesting,
                                      disabled: _vm.passwordReset.requesting,
                                      color: "accent",
                                      outline: "",
                                      round: ""
                                    },
                                    on: { click: _vm.requestPasswordReset }
                                  },
                                  [
                                    _vm._v(
                                      "\n              Request Password Reset\n              "
                                    )
                                  ]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      block: "",
                                      large: "",
                                      flat: "",
                                      color: "secondary",
                                      round: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.setCurrentSubView(true)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              Sign In\n              "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.passwordReset.requested
                          ? _c(
                              "div",
                              { staticStyle: { flex: "1" } },
                              [
                                _c("br"),
                                _c(
                                  "v-card",
                                  { attrs: { color: "success", dark: "" } },
                                  [
                                    _c(
                                      "v-card-title",
                                      { attrs: { "primary-title": "" } },
                                      [
                                        _c("h2", { staticClass: "fun-font" }, [
                                          _vm._v(
                                            "Sent, check your inbox for further instructions. See you shortly."
                                          )
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: {
            "xmulti-line": "true",
            timeout: 4000,
            "auto-height": "",
            bottom: "",
            value: _vm.viewError,
            color: "error"
          }
        },
        [
          _vm._v("\n    " + _vm._s(_vm.viewError) + "\n    "),
          _c(
            "v-btn",
            {
              attrs: { cxolor: "pink", fab: "", flat: "" },
              on: {
                click: function($event) {
                  _vm.viewError = null
                }
              }
            },
            [_c("v-icon", { attrs: { dark: "" } }, [_vm._v("close")])],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "ver" }, [_vm._v(_vm._s(_vm.ver))]),
      _c("orgs-selector-dialog", {
        ref: "orgSelector",
        attrs: { "can-cancel": false }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }