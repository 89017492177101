var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-toolbar",
    _vm._b(
      {
        class: _vm.textColor,
        staticStyle: { padding: "10px 0 0 20px" },
        attrs: { absolute: "", flat: "", dense: "" }
      },
      "v-toolbar",
      _vm.$attrs,
      false
    ),
    [
      _vm.menuButton
        ? _c("v-toolbar-side-icon", {
            class: _vm.textColor,
            on: { click: _vm.toggleNavDrawer }
          })
        : _vm._e(),
      _vm.homeButton
        ? _c(
            "v-toolbar-side-icon",
            { class: _vm.textColor, on: { click: _vm.goHome } },
            [_c("v-icon", [_vm._v("home")])],
            1
          )
        : _vm._e(),
      _vm.backButton
        ? _c(
            "v-toolbar-side-icon",
            { class: _vm.textColor, on: { click: _vm.navBack } },
            [_c("v-icon", [_vm._v("chevron_left")])],
            1
          )
        : _vm._e(),
      !_vm.$slots.main || _vm.$vuetify.breakpoint.smAndUp
        ? _c(
            "v-toolbar-title",
            { class: [_vm.textColor, _vm.$slots.main ? "mr-4" : ""] },
            [_vm._v(_vm._s(_vm.title))]
          )
        : _vm._e(),
      _vm._t("main"),
      _vm.$slots.toolbarButtons ? _c("v-spacer") : _vm._e(),
      _vm._t("toolbarButtons")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }