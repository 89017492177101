




























































































































































































































import { G, util } from "@/globals";
import Vue from "vue";
import * as models from "@/models";
import { router, iViewSettings } from "@/router";
import { accounts } from "@/code/api.accounts";
import { Tracking } from "@/code/tracking";
import infoSlides from "@/components/info-slides.vue";
import { lookups } from "@/code/api.lookups";

export default Vue.extend({
  name: "App",
  components: { infoSlides },

  data: () => ({
    G: G.data
  }),
  watch: {
    $route(to, from) {}
  },
  created: function() {
    G.localCommonDb.db().isOpen;
    G.topLevelVue = this;
    G.data.navDrawerVisible = this.$vuetify.breakpoint.smAndUp;
  },
  mounted: function() {
    G.topLevelVue.$ga.exception({ exDescription: "Direct 1" });
    if (G.data.currentLogin) Tracking.userId.set(G.data.currentLogin.id);
    // G.showSnackbar("lool");
    // if (G.data.currentLogin) G.requestNotificationPermission();
  },
  computed: {
    customTitle: function(): string {
      document.title = G.data.customTitle ? G.data.customTitle : "MeInOne";
      return G.data.customTitle;
    },
    subIsActive: function(): boolean {
      return this.$route.path.length != 1;
    }
  },
  methods: {
    logout: function() {
      accounts.logout();
    }
  },
  props: {
    source: String
  }
});
