import { G } from "@/globals";
export class Tracking {
  static userId = {
    set: (userId: number) => {
      G.topLevelVue.$ga.set("userId", userId);
    },
    clear: () => {
      G.topLevelVue.$ga.set("userId", null);
    }
  };
  static video = {
    startRecordingFirstVideo: (loginId: number) => {
      G.topLevelVue.$ga.event(
        "Video Management",
        "Start Recording 1st Video",
        "Login: " + loginId.toString()
      );
    },
    finishRecordingFirstVideo: (loginId: number) => {
      G.topLevelVue.$ga.event(
        "Video Management",
        "Finish Recording 1st Video",
        "Login: " + loginId.toString()
      );
    },
    beginUpload: (loginId: number, fileSize: number) => {
      G.topLevelVue.$ga.event(
        "Video Management",
        "Begin Upload",
        "Login: " + loginId.toString(),
        fileSize
      );
    },
    completeUpload: (loginId: number) => {
      G.topLevelVue.$ga.event(
        "Video Management",
        "Complete Upload",
        "Login: " + loginId.toString()
      );
    },
    play: (videoUrl: string, value?: number) => {
      G.topLevelVue.$ga.event("Video Usage", "Play", videoUrl, value);
    },
    pause: (videoUrl: string, value?: number) => {
      G.topLevelVue.$ga.event("Video Usage", "Pause", videoUrl, value);
    },
    end: (videoUrl: string, value?: number) => {
      G.topLevelVue.$ga.event("Video Usage", "End", videoUrl, value);
    },
    //**AA */
    crossMark: (videoUrl: string, markInSeconds: number) => {
      G.topLevelVue.$ga.event(
        "Video Usage",
        markInSeconds.toString() + "s Mark",
        videoUrl
      );
    }
  };
  static seeker = {
    share: (id: number) => {
      G.topLevelVue.$ga.event("Seeker", "Share", "Login: " + id.toString());
    },
    copyLink: (id: number) => {
      G.topLevelVue.$ga.event("Seeker", "Copy Link", "Login: " + id.toString());
    }
  };
  static jobPost = {
    share: (id: number) => {
      G.topLevelVue.$ga.event("JobPost", "Share", "Login: " + id.toString());
    },
    copyLink: (id: number) => {
      G.topLevelVue.$ga.event(
        "JobPost",
        "Copy Link",
        "Login: " + id.toString()
      );
    }
  };
  static feedback = {
    post: (subject: string) => {
      G.topLevelVue.$ga.event("Feedback", "App General Form", subject);
    }
  };
  static messaging = {
    newThread: () => {
      G.topLevelVue.$ga.event("Messaging", "Thread Created");
    },
    newMessage: (bySeeker: boolean) => {
      G.topLevelVue.$ga.event(
        "Messaging",
        "Message Sent",
        `By ${bySeeker ? "Seeker to Recruiter" : "Recruiter to Seeker"}`
      );
    }
  };
  static pushMessaging = {
    AddFcmToken: () => {
      G.topLevelVue.$ga.event("Push Messaging", "FCM Token Added");
    },
    RemoveFcmToken: () => {
      G.topLevelVue.$ga.event("Push Messaging", "FCM Token Removed");
    }
  };
  static notifications = {
    PreAllowed: () => {
      G.topLevelVue.$ga.event("Notifications", "Pre-Allowed");
    },
    Allowed: () => {
      G.topLevelVue.$ga.event("Notifications", "Allowed");
    },
    Blocked: () => {
      G.topLevelVue.$ga.event("Notifications", "Blocked");
    }
  };
  static accounts = {
    resetPasswordRequest: () => {
      G.topLevelVue.$ga.event("Accounts", "Reset Password Request");
    },
    passwordReset: () => {
      G.topLevelVue.$ga.event("Accounts", "Password Reset");
    },
    signupStart: () => {
      G.topLevelVue.$ga.event("Accounts", "Start Signup");
    },
    signupFinish: () => {
      G.topLevelVue.$ga.event("Accounts", "Finish Signup");
    },
    activateStart: () => {
      G.topLevelVue.$ga.event("Accounts", "Start Activate");
    },
    activateFinish: () => {
      G.topLevelVue.$ga.event("Accounts", "Finish Activate");
    }
  };
}
