// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from "firebase/app";
import * as models from "@/models";
import { G } from "@/globals";

// Add the Performance Monitoring library
import "firebase/performance";
// Add the Performance Monitoring library
import "firebase/firestore";
// Add the Performance Monitoring library
import "firebase/messaging";
import { notiOps } from "@/code/notificationOps";

const firebaseConfig = {
  apiKey: "AIzaSyBjLmH9oNfj52kKqx3NiK-I8ftDWMWaNCc",
  authDomain: "meinone-3e50a.firebaseapp.com",
  databaseURL: "https://meinone-3e50a.firebaseio.com",
  projectId: "meinone-3e50a",
  storageBucket: "meinone-3e50a.appspot.com",
  messagingSenderId: "502978153908",
  appId: "1:502978153908:web:ab16317e5980a738"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Initialize Performance Monitoring and get a reference to the service
export const fbPref = firebase.performance();
export const fbDb = firebase.firestore();

let fMessaging: firebase.messaging.Messaging;

if (firebase.messaging.isSupported()) {
  fMessaging = firebase.messaging();
  fMessaging.onMessage((payload: models.pushMessage) => {
    console.log("Message received. ", payload);
    let pmo = new models.pushMessageObject(payload);

    if (pmo.notifyInForeground) {
      // const { title, ...options } = payload.notification;
      navigator.serviceWorker.ready.then(registration => {
        registration.showNotification(pmo.pm.notification.title, {
          body: pmo.pm.notification.body,
          icon:
            pmo.pm.notification.icon ||
            `./img/icons/apple-touch-icon-76x76.png`,
          badge: `./img/icons/apple-touch-icon-76x76.png`,
          image: pmo.pm.data.image,
          tag: pmo.pm.data.group,
          renotify: !!pmo.pm.data.group,
          data: { ...{ url: pmo.pm.notification.click_action }, ...pmo.pm.data }
        });
      });
    }
    notiOps.refresh();
    let command = pmo.command;
    if (command && command.name) {
      console.log("Command Received:", command);

      let commandName = command.name;
      if (commandName == "REFRESH_MESSAGES") {
        G.newMessagesWatch.getNewMessagesCountFromServer();
      }
      if (commandName == "REFRESH_NOTIFICATIONS") {
        // notiOps.refresh();
      }
    }
  });
}

export const fbMsg = fMessaging;
