import { server } from "@/code/server";
import * as models from "@/models";
// import { Tracking } from "@/code/tracking";

export class notifications {
  static getPaged = (skip: number, take: number) => {
    return server.Post<models.paged<models.notification>>(
      "notifications/get",
      { skip, take },
      true
    );
  };
  static getAfter = (lastReceivedId: number) => {
    return server.Post<models.notification[]>(
      "notifications/get",
      { afterId: lastReceivedId },
      true
    );
  };
  static markRead = (ids: number[]) => {
    return server.Post<boolean>("notifications/markRead", { ids }, true);
  };
}
