var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "app", dark: _vm.G.darkPage } },
    [
      _vm.G.currentLogin
        ? _c(
            "v-navigation-drawer",
            {
              staticClass: "primary",
              attrs: {
                absolute: "",
                temporary: !_vm.$vuetify.breakpoint.smAndUp,
                xpermanent: "",
                xfixed: "",
                app: "",
                xclass: "[G.isSeekerMode?'bg-sk':'bg-rc']",
                dark: ""
              },
              model: {
                value: _vm.G.navDrawerVisible,
                callback: function($$v) {
                  _vm.$set(_vm.G, "navDrawerVisible", $$v)
                },
                expression: "G.navDrawerVisible"
              }
            },
            [
              _c(
                "v-list",
                { attrs: { dense: !_vm.$vuetify.breakpoint.smAndUp } },
                [
                  _vm.G.isSeekerMode
                    ? [
                        _c(
                          "v-list-tile",
                          {
                            attrs: {
                              to: { name: "Home" },
                              "active-class": _vm.subIsActive
                                ? "sub-is-active"
                                : "primary--text",
                              xclass: "{'sub-is-active': }",
                              xdark: "subIsActive",
                              xxclass: "error--text"
                            }
                          },
                          [
                            _c(
                              "v-list-tile-action",
                              [_c("v-icon", [_vm._v("home")])],
                              1
                            ),
                            _c(
                              "v-list-tile-content",
                              [_c("v-list-tile-title", [_vm._v("Home")])],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-list-tile",
                          { attrs: { to: { name: "Seekers_Discover" } } },
                          [
                            _c(
                              "v-list-tile-action",
                              [_c("v-icon", [_vm._v("explore")])],
                              1
                            ),
                            _c(
                              "v-list-tile-content",
                              [_c("v-list-tile-title", [_vm._v("Discover")])],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-list-tile",
                          { attrs: { to: { name: "Seekers_MyProfile" } } },
                          [
                            _c(
                              "v-list-tile-action",
                              [_c("v-icon", [_vm._v("account_circle")])],
                              1
                            ),
                            _c(
                              "v-list-tile-content",
                              [_c("v-list-tile-title", [_vm._v("My Profile")])],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    : _vm._e(),
                  !_vm.G.isSeekerMode
                    ? [
                        _c(
                          "v-list-tile",
                          {
                            attrs: {
                              to: { name: "Home" },
                              "active-class": _vm.subIsActive
                                ? "sub-is-active"
                                : "primary--text",
                              xclass: "{'sub-is-active': }",
                              xdark: "subIsActive",
                              xxclass: "error--text"
                            }
                          },
                          [
                            _c(
                              "v-list-tile-action",
                              [_c("v-icon", [_vm._v("home")])],
                              1
                            ),
                            _c(
                              "v-list-tile-content",
                              [_c("v-list-tile-title", [_vm._v("Home")])],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-list-tile",
                          { attrs: { to: { name: "Orgs_Discover" } } },
                          [
                            _c(
                              "v-list-tile-action",
                              [_c("v-icon", [_vm._v("explore")])],
                              1
                            ),
                            _c(
                              "v-list-tile-content",
                              [_c("v-list-tile-title", [_vm._v("Discover")])],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-list-tile",
                          { attrs: { to: { name: "Orgs_JobPosts" } } },
                          [
                            _c(
                              "v-list-tile-action",
                              [_c("v-icon", [_vm._v("work")])],
                              1
                            ),
                            _c(
                              "v-list-tile-content",
                              [_c("v-list-tile-title", [_vm._v("Job Posts")])],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    : _vm._e(),
                  _c(
                    "v-list-tile",
                    { attrs: { to: { name: "Messages" } } },
                    [
                      _c(
                        "v-list-tile-action",
                        [
                          _c(
                            "v-badge",
                            {
                              attrs: {
                                color: "red",
                                value:
                                  _vm.G.messagesWatch &&
                                  _vm.G.messagesWatch.newMessagesCount > 0
                              }
                            },
                            [
                              _c("template", { slot: "badge" }, [
                                _c("span", {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.G.messagesWatch.newMessagesCount > 99
                                        ? "99+"
                                        : _vm.G.messagesWatch.newMessagesCount
                                    )
                                  }
                                })
                              ]),
                              _c("v-icon", [_vm._v("message")])
                            ],
                            2
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-list-tile-content",
                        [_c("v-list-tile-title", [_vm._v("Messages")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-divider", { attrs: { dark: "" } }),
                  _c(
                    "v-list-tile",
                    {
                      on: {
                        click: function($event) {
                          _vm.G.feedbackVisible = true
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-tile-action",
                        [_c("v-icon", [_vm._v("feedback")])],
                        1
                      ),
                      _c(
                        "v-list-tile-content",
                        [
                          _c("v-list-tile-title", [
                            _vm._v("Support & Feedback")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-divider", { attrs: { dark: "" } }),
                  _c(
                    "v-list-tile",
                    { on: { click: _vm.logout } },
                    [
                      _c(
                        "v-list-tile-action",
                        [_c("v-icon", [_vm._v("remove")])],
                        1
                      ),
                      _c(
                        "v-list-tile-content",
                        [_c("v-list-tile-title", [_vm._v("Log Out")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        : _vm._e(),
      _c("v-content", [_c("router-view", { ref: "mainRouter" })], 1),
      _c("feedback-dialog", {
        model: {
          value: _vm.G.feedbackVisible,
          callback: function($$v) {
            _vm.$set(_vm.G, "feedbackVisible", $$v)
          },
          expression: "G.feedbackVisible"
        }
      }),
      _c("info-slides", {
        attrs: { collectionId: _vm.G.infoSlides.collectionId },
        model: {
          value: _vm.G.infoSlides.visible,
          callback: function($$v) {
            _vm.$set(_vm.G.infoSlides, "visible", $$v)
          },
          expression: "G.infoSlides.visible"
        }
      }),
      _c(
        "v-snackbar",
        {
          attrs: {
            "multi-line": "",
            timeout: 5000,
            "auto-height": "",
            bottom: "",
            right: _vm.$vuetify.breakpoint.smAndUp,
            color: _vm.G.snackbar.color
          },
          model: {
            value: _vm.G.snackbar.active,
            callback: function($$v) {
              _vm.$set(_vm.G.snackbar, "active", $$v)
            },
            expression: "G.snackbar.active"
          }
        },
        [
          _c("span", {
            domProps: { innerHTML: _vm._s(_vm.G.snackbar.message) }
          }),
          _c(
            "v-btn",
            {
              attrs: { cxolor: "pink", fab: "", flat: "" },
              on: {
                click: function($event) {
                  _vm.G.snackbar.active = false
                }
              }
            },
            [_c("v-icon", { attrs: { dark: "" } }, [_vm._v("close")])],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "hide-overlay": "", persistent: "", width: "300" },
          model: {
            value: _vm.G.firstLoading,
            callback: function($$v) {
              _vm.$set(_vm.G, "firstLoading", $$v)
            },
            expression: "G.firstLoading"
          }
        },
        [
          _c(
            "v-card",
            { attrs: { color: "primary", dark: "" } },
            [
              _c(
                "v-card-text",
                [
                  _vm._v("\n        Please stand by...\n        "),
                  _c("v-progress-linear", {
                    staticClass: "mb-0",
                    attrs: { indeterminate: "", color: "white" }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }