import * as models from "@/models";
import { Tracking } from "@/code/tracking";

import logger from "@/code/logger";
import { fbPref, fbMsg } from "@/code/firebase";
import { accounts } from "@/code/api.accounts";
import db from "@/code/localDb";
// import * as confirm from "@/components/confirm/confirm";
import { colors } from "@/main";
import { messaging } from "@/code/api.messaging";
// import { SweetAlertOptions } from "sweetalert2";

//#region  AppInsights
import { AppInsights } from "applicationinsights-js";
import Vue from "vue";
//

AppInsights.downloadAndSetup({
  instrumentationKey: "563384b7-02c1-4681-8676-991192d3b3ff"
});
(<any>window).AppInsights = AppInsights;
//#endregion

/**
 * @class globals
 *
 */
export const G = {
  data: {
    firstLoading: true,
    darkPage: false,
    feedbackVisible: false,
    waitForAutoLogin: loadCurrentUser,
    currentLogin: <models.login>null,
    navDrawerVisible: false,
    ver: "v1.3.115",
    customTitle: <string>null,
    //**true: seeker UI, false: recruiter UI */
    isSeekerMode: true,

    snackbar: {
      active: false,
      message: null as string,
      color: "default"
    },

    messagesWatch: {
      watchHandler: null,
      busyCallingServer: false,
      newMessagesCount: 0
    },
    appInsights: AppInsights,
    infoSlides: {
      collectionId: null as number,
      visible: false
    },
    notificationsData: {
      pagedNotifications: null as models.paged<models.notification>,
      pageSize: 8,
      loadingMore: false,
      newCount: 0
    }
  },
  requestNotificationPermission: async () => {
    const permission: "granted" | "default" | "denied" = await (<any>(
      window
    )).Notification.requestPermission();
    // value of permission can be 'granted', 'default', 'denied'
    // granted: user has accepted the request
    // default: user has dismissed the notification permission popup by clicking on x
    // denied: user has denied the request.
    if (permission == "granted") {
      console.log("Notification Grandted!");
      Tracking.notifications.Allowed();
    } else if (permission == "denied") {
      console.warn("Permission not granted for Notification");
      Tracking.notifications.Blocked();
    } else if (permission == "default") {
      //
    }
    return permission;
  },
  initNotificationsIfSupported: () => {
    //#region FCM
    if (fbMsg) {
      import(
        /* webpackChunkName: "notificationOps" */ "@/code/notificationOps"
      ).then(ops => {
        ops.initNotification();
      });
    }

    //#endregion
  },
  fbPref: fbPref,
  switchMode: function() {
    G.data.isSeekerMode ? G.switchToRecruiterMode() : G.switchToSeekerMode();
    G.newMessagesWatch.restart();
  },
  switchToSeekerMode: function() {
    G.setSeekerMode(true, null, true);
    // G.topLevelVue.$router.push({ name: "Seekers_Discover" });
    G.topLevelVue.$router.push({ name: "Home" });
  },
  switchToRecruiterMode: function() {
    G.setSeekerMode(false, null, true);
    // G.topLevelVue.$router.push({ name: "Orgs_Discover" });
    G.topLevelVue.$router.push({ name: "Home" });
  },
  topLevelVue: null,
  // static get currentLogin(): models.login {
  //   let value = localStorage.getItem("__currentUser");
  //   return value ? JSON.parse(value) : null;
  // }
  setCurrentLogin: (value: models.login) => {
    if (value) localStorage.setItem("__currentUser", JSON.stringify(value));
    else localStorage.removeItem("__currentUser");
    G.data.currentLogin = value;
  },
  get localFcmToken(): models.localFcmToken {
    let value = localStorage.getItem("__localFcmTokenObject");
    return value ? JSON.parse(value) : null;
  },
  set localFcmToken(val: models.localFcmToken) {
    if (val) localStorage.setItem("__localFcmTokenObject", JSON.stringify(val));
    else localStorage.removeItem("__localFcmTokenObject");
  },
  setSeekerMode: function(value: boolean, app: any, updateSettings?: boolean) {
    if (!app) app = G.topLevelVue;
    G.data.isSeekerMode = value;
    if (updateSettings) accounts.isSeekerMode = value;
    if (value) {
      // app.$vuetify.theme.primary = "#455A64";
      // app.$vuetify.theme.secondary = "#78909C";
      app.$vuetify.theme = {
        ...app.$vuetify.theme,
        ...colors.seeker
      };
    } else {
      // app.$vuetify.theme.primary = "#512DA8";
      // app.$vuetify.theme.secondary = "#7E57C2";
      app.$vuetify.theme = {
        ...app.$vuetify.theme,
        ...colors.recruiter
      };
    }
    {
      let content1 = document.querySelector("[name='theme-color']");
      let content2 = document.querySelector("[name='msapplication-TileColor']");
      let color = document.querySelector("[rel='mask-icon']");
      content1.setAttribute("content", app.$vuetify.theme.primary);
      content2.setAttribute("content", app.$vuetify.theme.primary);
      color.setAttribute("color", app.$vuetify.theme.primary);
      //
      //setAttribute
    }
  },
  log: {
    error: (location: string, error: string | {}, dev?: boolean): void => {
      dev
        ? logger.logDevError(location, error)
        : logger.logError(location, error);

      if (!dev && !!G.topLevelVue) {
        console.info("informing GA");
        G.topLevelVue.$ga.exception({ exDescription: error });
      }
    },
    action: (location: string, action: any, dev?: boolean): void => {
      dev
        ? logger.logDevAction(location, action)
        : logger.logAction(location, action);
    }
  },
  localDb: {
    db: () => {
      if (!G.data.currentLogin) return null;
      return new db(G.data.currentLogin.id);
    },
    clearDb: (id: number) => {
      return db.clearDb(id);
    }
  },
  localCommonDb: {
    db: () => {
      return new db("common");
    },
    clearDb: () => {
      return db.clearDb("common");
    }
  },
  // confirmDialog: <confirm.confirmDialogWrapper>null,
  confirmDialog: {
    open: (
      text: string,
      title?: string,
      cancelText?: string,
      confirmText?: string,
      cancelColor?: string,
      confirmColor?: string
    ): Promise<boolean> => {
      // title: string, message?: string, type?: SweetAlertType
      //confirmText: confirmText | 'OK'
      let opts = {
        //: xSweetAlertOptions
        title: title,
        // text: "text",
        html: text,
        type: "question",
        allowEnterKey: true,
        allowOutsideClick: false,
        confirmButtonText: confirmText || "OK",
        cancelButtonText: cancelText || "Cancel",
        showCancelButton: true
      };
      // return Promise.resolve(false);
      return G.topLevelVue.$swal(opts).then(result => {
        return !!result.value;
      });
    }
  },
  showLoading: function(customMessage?: string, blocking?: boolean): void {},
  hideLoading: function(): void {},
  showSnackbar: function(message: string, color?: string): void {
    G.data.snackbar.message = message;
    G.data.snackbar.color = color || "primary";
    G.data.snackbar.active = true;
  },
  getQueryString: function(routeQuery: any, key: string): string {
    let idx = Object.keys(routeQuery)
      .map(k => k.toLowerCase())
      .findIndex(k2 => k2 == key.toLowerCase());
    if (idx == -1) {
      return null;
    } else {
      return Object.values<string>(routeQuery)[idx];
    }
  },

  newMessagesWatch: {
    getNewMessagesCountFromServer: function() {
      // get Count from
      if (G.data.messagesWatch.busyCallingServer) return;
      G.data.messagesWatch.busyCallingServer = true;
      messaging
        .getNewMessagesCount(G.data.isSeekerMode ? null : accounts.currentOrgId)
        .then(c => {
          G.data.messagesWatch.newMessagesCount = c;
          console.log("New Messages Count", c);
        })
        .finally(() => {
          G.data.messagesWatch.busyCallingServer = false;
        });
    },
    start: function() {
      let win = <any>window;
      let permission =
        !win.Notification || !win.Notification.permission
          ? "default"
          : win.Notification.permission;

      if (permission == "granted") {
        G.newMessagesWatch.getNewMessagesCountFromServer();
        console.log(
          "New messages watch was not started, because push messaging is handling it."
        );
      } else {
        if (G.data.messagesWatch.watchHandler) {
          clearInterval(G.data.messagesWatch.watchHandler);
        }
        G.newMessagesWatch.getNewMessagesCountFromServer();
        G.data.messagesWatch.watchHandler = setInterval(
          G.newMessagesWatch.getNewMessagesCountFromServer,
          10 * 1000
        );
        console.log("New messages watch started.");
      }
    },
    stop: function() {
      if (G.data.messagesWatch.watchHandler)
        clearInterval(G.data.messagesWatch.watchHandler);
    },
    restart: function() {
      G.newMessagesWatch.stop();
      G.data.messagesWatch.newMessagesCount = 0;
      G.newMessagesWatch.start();
    }
  }
};

export class rules {
  static required = (value: any) => !!value || "Required";

  //   static maxCounter = (value: any) =>
  //     value.length <= max || "Max " /*+ max.toString() */ + " characters";
  static email = (value: any) => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(value) || "Invalid e-mail";
  };

  static maxCounter = (max: number) => (value: any) =>
    (value && value.length <= max) || "Max " + max.toString() + " characters";

  static stringLength = (min: number, max: number) => (value: any) =>
    (value && value.length <= max && value.length >= min) ||
    `Length must be between ${min.toString()} and ${max.toString()}`;

  static passwordLength = rules.stringLength(8, 24);
}

// // init.
// {
//   // Load Cached
//   {
//     // Current User
//     let value = localStorage.getItem("__currentUser");
//     G.data.currentLogin = value ? JSON.parse(value) : null;

//     if (G.data.currentLogin) {
//       accounts.loginCurrentUser();
//     } else {
//       G.data.waitForAutoLogin = Promise.resolve();
//     }
//   }
// }
let _loadCurrentUserDone = false;
function loadCurrentUser() {
  if (_loadCurrentUserDone) return Promise.resolve();
  console.log("loadCurrentUser...");

  _loadCurrentUserDone = true;
  // Current User
  let value = localStorage.getItem("__currentUser");
  G.data.currentLogin = value ? JSON.parse(value) : null;

  if (G.data.currentLogin) {
    return accounts.loginCurrentUser();
  } else {
    G.data.firstLoading = false;
    return Promise.resolve();
  }
}
export class util {
  static get isLocalhost(): boolean {
    return window.location.href.startsWith("http://localhost");
  }

  static DELETED_ORIGINAL_copyStringToClipboard(value: string) {
    // Create new element
    var el = document.createElement("textarea");
    // Set value (string to be copied)
    el.value = value;
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    document.execCommand("copy");
    // Remove temporary element
    document.body.removeChild(el);
  }

  static get isShareSupported(): boolean {
    let Navigator: any = navigator;
    return Navigator.share !== undefined;
  }
  static share(title: string, url: string) {
    let Navigator: any = navigator;
    if (util.isShareSupported) {
      Navigator.share({
        title: title,
        url: url
      })
        .then(() => {
          console.log("Successful share");
          return true;
        })
        .catch(error => {
          console.error("Error sharing:", error);
          return false;
        });
    } else {
      console.error(
        "Unfortunately, this feature is not supported on your browser"
      );
      return false;
    }
  }
}
export type progressHandler = (percentage: number) => void;

export interface toolbarItem {
  icon: string;
  // text: string;
  color?: string;
  onClick?: (...args: any[]) => any;
}
