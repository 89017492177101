import { render, staticRenderFns } from "./m-tabs.vue?vue&type=template&id=02a05859&"
import script from "./m-tabs.vue?vue&type=script&lang=ts&"
export * from "./m-tabs.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!D:/Workspaces/Evidence/main/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib'
import { VCardActions } from 'vuetify/lib'
import { VSpacer } from 'vuetify/lib'
installComponents(component, {VBtn,VCardActions,VSpacer})


/* hot reload */
if (module.hot) {
  var api = require("D:\\Workspaces\\Evidence\\main\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('02a05859', component.options)
    } else {
      api.reload('02a05859', component.options)
    }
    module.hot.accept("./m-tabs.vue?vue&type=template&id=02a05859&", function () {
      api.rerender('02a05859', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/ui/m-tabs.vue"
export default component.exports